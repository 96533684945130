.post-type-archive-medecin{

  .title{
    margin-bottom: 50px;

    h1{
      position: relative;
      font-size: 36px;
      margin: 40px 0 36px 50px;
      font-weight: 700;

      &::before{
        content: "";
        position: absolute;
        left: -50px;
        top: 50%;
        background-color: #6BB2E3;
        height: 4px;
        width: 30px;
      }
    }
  }

  .list-doctors{
    margin-bottom: 60px;
    article{
      display: flex;
      background-color: #F7F7F7;
      border-radius: 10px;
      padding: 45px;

      &:not(:last-child){
        margin-bottom: 50px;
      }

      .picture{
        width: 25%;
        max-width: 200px;
        min-width: 200px;

        figure{

          img{
            border-radius: 50%;
            border: solid 3px #6BB2E3;
          }
        }
      }

      .doctor-profile{
        width: 35%;
        position: relative;
        margin-left: 64px;
        max-height: 200px;

        h2{
          margin-bottom: 10px;
        }
        h3, p{
          margin-bottom: 7px;
        }
      }

      .text-container{
        width: 40%;
        margin-left: 15px;
        position: relative;
        padding-right: 15px;

        &::before{
          content: "";
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          background-color: #6BB2E3;
          opacity: .3;
        }

        p{
          margin-left: 46px;
        }

        ul{
          padding-left: 46px;

          li{
            list-style-type: disc;
            color: #153153;

            &:not(:last-child){
              margin-bottom: 20px;
            }
          }
        }
      }

      .cta{

        &.detail{
          color: #ffffff;
          font-size: 16px;
          line-height: 1;
          display: inline-block;
          margin-top: 27px;
          background-color: #467CA0;
          padding: 14px 20px;
          border-radius: 24px;
          text-align: center;
        }
      }
    }
  }
}
