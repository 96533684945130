.iso-9001{
  padding: 100px 0;
  position: relative;
  .advgb-columns {
    background-color: #F7F7F7;
    border-radius: 10px;
    .wp-block-advgb-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-wrap: nowrap;
      padding: 40px 50px;
      .advgb-column-inner{
        h3,p{
          color: #17385e;
        }
        h3{
          margin-bottom: 15px;
        }
        p{
          padding-right: 95px;
        }
      }
    }
  }
  .iso-9001-column-text{
    position: relative;
    .iso-9001-link{
      margin-top: 20px;
      a{
        border: none !important;
        border-bottom: 3px solid #6BB2E3 !important;
        padding: 0 34px 11px 0;
        border-radius: 0;
        position: relative;
        &::after{
          content: '';
          display: block;
          width: 19px;
          height: 18px;
          position: absolute;
          top: 9px;
          right: 0;
          background-image: url("../../../images/cta-arrow-right.svg");
          background-repeat: no-repeat;
        }
        &:hover{
          border-bottom: 3px solid #17385e !important;
          &::after{
            filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
          }
        }
      }
    }
    &::before{
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: calc(100% - 80px);
      background-color: #6BB2E3;
      left: 0;
    }
  }
}
