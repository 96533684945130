footer{
	background-color: #ffffff;
	.menu_footer{
		background-image: linear-gradient(to bottom, #173150, #17385e);
		padding: 60px 0 0;

		a,p{
			color: #ffffff;
			font-size: 16px;
			font-family: $openSans-bold;
			line-height: normal;
			font-weight: normal;
		}

		.footer-flex{
			display: flex;
			position: relative;
			max-width: 1440px;
			margin-bottom: 68px;
		}

		.menu{
			display: flex;
			flex-wrap: wrap;
			height: fit-content;

			.menu-item{
				flex: 1 1 50%;
				padding-bottom: 20px;
			}
		}

		.networks{
			display: flex;
			flex-direction: column;

			p{
				font-family: $openSans-regular;
				font-size: 15px;
				flex: 0 0 40px;
			}
			.logos{
				display: flex;

				a{
					padding-right: 30px;
				}

				img{
					min-width: 24px;
					min-height: 24px;
				}
			}
		}

		.button-footer{
			display: flex;
			flex-wrap: wrap;
			align-content: center;
			align-items: center;
			flex-direction: column;
			padding: 30px 0 0;
			a{
				background-color: #467CA0;
				border: 2px solid #467CA0;
				padding: 14px 25px;
				border-radius: 25px;
				line-height: 1;
				&:hover{
					background-color: #153153;
					border: 2px solid #FFFFFF;
				}
			}
		}

		.scrollUp{
			background-color: #ffffff;
			border-radius: 100%;
			box-shadow: 0 0 9px 0 rgba(23, 56, 94, 0.15);
			position: absolute;
			top: -60px;
			right: 90px;
			width: 54px;
			height: 54px;
			transform: translateY(-50%);
			cursor: pointer;
			z-index: 1000;

			&:hover{
				background-color: #467CA0;

				.icon-arrow-up{
					filter: brightness(1000) sepia(15) hue-rotate(0deg) saturate(0);
				}
			}
			.icon{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			.icon-arrow-up{
				display: block;
				width: 25px;
				height: 25px;
				background-image: url("../../images/backtothetop-arrow-up.svg");
				&:hover{
					filter: brightness(1000) sepia(15) hue-rotate(0deg) saturate(0);
				}
			}
		}

		.copyright{
			background-color: #0C2440;
			padding: 10px 0;
			a,p{
				font-family: $openSans-regular;
				font-size: 16px;
				text-align: center;
				line-height: normal;
			}

			a{
				text-decoration: underline;
			}
		}
	}
}
.tarte-script{
	p{
		padding: 0;
	}
}
