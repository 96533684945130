.block-double-cta{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          .block-double-cta-white{
            a{
              &:hover{
                border-color: #153153 !important;
              }
            }
          }
        }
      }
    }
  }
}