.tlv-begin-block {
  display: flex;
  border: 2px solid #E3F1FB;
  .tlv-begin-block-column-one {
    padding: 60px 40px 0 60px;
  }
  .tlv-begin-block-column-two {
    background-color: #E3F1FB;
    padding: 60px 40px;
    .content {
      margin-bottom: 35px;
    }
    .tlv-button-start {
      cursor: pointer;
      display: block;
      position: relative;
      width: fit-content;
      border-radius: 35px;
      background-color: #467CA0;
      padding: 15px 51px 15px 25px;
      color: white;
      line-height: 22px;
      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 18px;
        right: 20px;
        width: 16px;
        height: 16px;
        background-image: url("../../../images/arrow-up-white.svg");
        background-size: contain;
        transform: rotate(90deg);
      }
    }
  }
}
.tlv-block {
  position: relative;
  width: 100vw;
  top: -180px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #E3F1FB;
  .title {
    padding-top: 50px;
    padding-bottom: 35px;
    font-size: 36px;
    line-height: 49px;
  }

  .form-step {
    padding-bottom: 60px;
    .about-you {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: stretch;
      border-radius: 20px;
      background-color: $white;
      padding: 50px 75px;
      .about-you-row {
        h3 {
          color: $primary_color;
          margin-bottom: 20px;
        }
        .row-input-age {
          display: flex;
          .inner-row-input-age {
            flex: 0 1 15%;
            max-width: 15%;
            margin-bottom: 30px;
            .age-item-label{
              display: flex;
              width: 100%;
              padding: 15px;
              cursor: pointer;
              color: $primary_color;
              border: 1px solid $light-blue;
              border-radius: 14px;
              font-family: $openSans-bold;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              height: 45px;
              align-items: center;
              justify-content: center;
            }

            input[type=radio]{
              display: none;
            }

            input[type=radio]:checked + .age-item-label{
              background-color: $primary_color;
              color: $white;
            }
            &:not(:last-child) {
              margin-right: 20px;
            }
          }
        }
        .row-input-correction {
          display: flex;
          .inner-row-input-correction {
            margin-bottom: 30px;
            .correction-item-label {
              position: relative;
              display: flex;
              width: 100%;
              padding: 15px 20px;
              cursor: pointer;
              color: $primary_color;
              border: 1px solid $light-blue;
              border-radius: 14px;
              font-family: $openSans-bold;
              font-size: 16px;
              line-height: 22px;
              text-align: center;
              height: 45px;
              align-items: center;
              justify-content: center;
              &::after {
                content: '';
                position: absolute;
                display: block;
                top: 10px;
                left: 25px;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }

            input[type=radio]{
              display: none;
            }

            input[type=radio]:checked + .correction-item-label{
              background-color: $primary_color;
              color: $white;
              &::after {
                filter: brightness(1000) sepia(15) hue-rotate(0deg) saturate(0);
              }
            }
            &:not(:last-child) {
              margin-right: 20px;
            }
            &:first-child {
              .correction-item-label {
                padding-left: 75px;
                &::after {
                  width: 38px;
                  height: 25px;
                  background-image: url("../../../images/PV_Lunettes.svg");
                }
              }
            }
            &:nth-child(2) {
              .correction-item-label {
                padding-left: 75px;
                &::after {
                  width: 44px;
                  height: 25px;
                  background-image: url("../../../images/PV_Lentilles.svg");
                }
              }
            }
            &:nth-child(3) {
              .correction-item-label {
                padding-left: 85px;
                &::after {
                  width: 33px;
                  height: 27px;
                  background-image: url("../../../images/PV_Lunettes+lentilles.svg");
                }
              }
            }
            &:last-child {
              .correction-item-label {
                padding-left: 60px;
                &::after {
                  top: 13px;
                  width: 19px;
                  height: 19px;
                  background-image: url("../../../images/PV_Aucun.svg");
                }
              }
            }
          }
        }
      }
      .button-row {
        justify-content: flex-end;
      }

    }
    .instructions-calibrations {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      border-radius: 20px;
      background-color: $white;
      padding: 50px 75px;
      h3 {
        color: $primary_color;
        margin-bottom: 20px;
      }
      p {
        margin-bottom: 20px;
      }
      .calibration-row {
        .calibration-rule {
          display: flex;
          align-items: center;
          justify-content: center;
          #zoom-plus,
          #zoom-moins {
            position: relative;
            width: 44px;
            height: 44px;
            border: 2px solid $light-blue;
            border-radius: 14px;
            cursor: pointer;
            &::after {
              content: "";
              position: absolute;
              display: flex;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 44px;
              height: 44px;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              font-family: $openSans-bold;
              font-size: 20px;
              line-height: 27px;
              color: $primary_color;
            }
          }
          #zoom-moins::after {
            content: "-";
          }
          #zoom-plus::after {
            content: "+";
          }
          #calibration-piece {
            margin: 0 30px;
            #piece-one-euro {
              width: 100px;
            }
          }
        }
      }
      .condition-row {
        .poppin-condition {
          margin-bottom: 10px;
          cursor: pointer;
        }
        .consent {
          margin-bottom: 35px;
          .consent-label {
            position: relative;
            width: 100%;
            padding-left: 30px;
            cursor: pointer;
            color: $black;
            font-family: $openSans-regular;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              height: 21px;
              width: 21px;
              border: 1px solid $primary_color;
              border-radius: 4px;
              background-color: #FFFFFF;
            }
          }

          input[type=checkbox]{
            display: none;
          }

          input[type=checkbox]:checked + .consent-label::before {
            background-color: $primary_color;
          }
        }
      }
      .button-row {
        width: 100%;
        justify-content: space-between;
      }
    }
    .distance-vision {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 20px;
      background-color: $white;
      padding: 50px 75px;
      .instruction-left-eye-row,
      .instruction-right-eye-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        .inner-instruction-eye-row {
          display: flex;
          margin-bottom: 35px;
          .instruction-one,
          .instruction-two {
            padding: 40px 50px;
            border: 2px solid #6BB2E3;
            border-radius: 10px;
            background-color: #E3F1FB;
            flex: 0 1 50%;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .picture {
              position: relative;
              display: block;
              width: 70px;
              height: 70px;
              background-color: white;
              border-radius: 50%;
              margin-bottom: 20px;
              &::after {
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 46px;
                height: 24px;
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.eye-left {
                &::after {
                  background-image: url("../../../images/PV_Oeil-gauche.svg");
                }
              }
              &.eye-right {
                &::after {
                  background-image: url("../../../images/PV_Oeil-droit.svg");
                }
              }
              &.distance {
                &::after {
                  background-image: url("../../../images/PV_1m.svg");
                }
              }
            }
            .label {
              font-family: $openSans-bold;
              font-size: 20px;
              line-height: 27px;
              text-align: center;
              color: $primary_color;
              max-width: 190px;
            }
          }
          >div:not(:last-child) {
            margin-right: 20px;
          }
        }
        .acceptance {
          .button-acceptance {
            cursor: pointer;
            display: block;
            width: fit-content;
            border-radius: 35px;
            background-color: #467CA0;
            padding: 15px 25px;
            color: white;
            line-height: 22px;
          }
        }
      }
      .test-eye-row {
        .instruction-test {
          color: $primary_color;
          font-family: $openSans-bold;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
        }
        .test-eye {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          >div {
            div{
              img {
                max-width: 114px;
              }
            }
          }
          #center-row {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            height: 180px;
          }
          #up, #left, #right, #down {
            cursor: pointer;
          }
          #center {
            margin: 20px;
            width: 100px;
            img {
              //width: 14px;
              margin: 0 auto;
            }
          }
          #left {
            img {
              transform: rotate(270deg);
            }
          }
          #right {
            img {
              transform: rotate(90deg);
            }
          }
          #down {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }
      .button-row {
        justify-content: space-between;
      }
    }
    .dmla-vision {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 20px;
      background-color: $white;
      padding: 50px 75px;
      .instruction-left-eye-row,
      .instruction-right-eye-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        .inner-instruction-eye-row {
          display: flex;
          margin-bottom: 35px;
          .instruction-one,
          .instruction-two {
            padding: 40px 50px;
            border: 2px solid #6BB2E3;
            border-radius: 10px;
            background-color: #E3F1FB;
            flex: 0 1 50%;
            max-width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .picture {
              position: relative;
              display: block;
              width: 70px;
              height: 70px;
              background-color: white;
              border-radius: 50%;
              margin-bottom: 20px;
              &::after {
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 46px;
                height: 24px;
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.eye-left {
                &::after {
                  background-image: url("../../../images/PV_Oeil-gauche.svg");
                }
              }
              &.eye-right {
                &::after {
                  background-image: url("../../../images/PV_Oeil-droit.svg");
                }
              }
              &.distance {
                &::after {
                  background-image: url("../../../images/PV_40cm.svg");
                }
              }
            }
            .label {
              font-family: $openSans-bold;
              font-size: 20px;
              line-height: 27px;
              text-align: center;
              color: $primary_color;
              max-width: 190px;
            }
          }
          >div:not(:last-child) {
            margin-right: 20px;
          }
        }
        .acceptance {
          .button-acceptance {
            cursor: pointer;
            display: block;
            width: fit-content;
            border-radius: 35px;
            background-color: #467CA0;
            padding: 15px 25px;
            color: white;
            line-height: 22px;
          }
        }
      }
      .test-eye-row {
        .instruction-test {
          color: $primary_color;
          font-family: $openSans-bold;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          margin-bottom: 60px;
        }
        .test-amsler {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          img {
            margin: 0 auto;
            width: 100%;
            max-width: 340px;
          }
          p {
            margin-top: 60px;
            margin-bottom: 35px;
            font-family: $openSans-bold;
            font-size: 20px;
            line-height: 27px;
          }
          .answer-button-row {
            display: flex;
            justify-content: center;
            .button {
              cursor: pointer;
              display: block;
              position: relative;
              width: fit-content;
              border-radius: 35px;
              background-color: #467CA0;
              padding: 15px 25px;
              color: white;
              line-height: 22px;
              &:not(:last-child) {
                margin-right: 20px;
              }
              &:disabled {
                opacity: 0.3;
                cursor: default;
              }
              &.validate {
                background-color: $primary_color;
              }
            }
          }
        }
      }
    }
    .result-row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      border-radius: 20px;
      background-color: $white;
      padding: 50px 75px;
      .result-asmler-eyes {
        display: flex;
        .inner-result-asmler {
          flex: 0 1 50%;
          max-width: 50%;
          display: flex;
          padding: 40px;
          border-radius: 10px;
          background-color: $primary_color;
          .column-picture {
            .picture {
              position: relative;
              display: block;
              width: 70px;
              height: 70px;
              background-color: white;
              border-radius: 50%;
              margin-bottom: 20px;
              &::after {
                content: '';
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 46px;
                height: 24px;
                background-size: contain;
                background-repeat: no-repeat;
              }
              &.eye-left {
                &::after {
                  background-image: url("../../../images/PV_Oeil-gauche.svg");
                }
              }
              &.eye-right {
                &::after {
                  background-image: url("../../../images/PV_Oeil-droit.svg");
                }
              }
            }
          }
          .column-content {
            padding-left: 40px;
            .label {
              color: $white;
              font-family: $openSans-bold;
              text-transform: capitalize;
              font-size: 20px;
              line-height: 27px;
              margin-bottom: 10px;
            }
            #result-test-asmler-left,
            #result-test-asmler-right {
              color: $white;
              font-family: $openSans-regular;
              font-size: 16px;
              line-height: 24px;
            }
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
      .result-global-eyes {
        margin-top: 35px;
        h3 {
          color: $primary_color;
          margin-bottom: 20px;
        }
        .info {

        }
        #result-global-eyes {

        }
      }
      .tests-result-row {
        display: flex;
        margin-top: 35px;
        margin-bottom: 35px;
        .results-row {
          flex: 0 1 50%;
          max-width: 50%;
          h3 {
            color: $primary_color;
            margin-bottom: 20px;
          }
          .label {
            color: $primary_color;
            font-family: $openSans-bold;
            margin-bottom: 25px;
          }
          &:last-child {
            border-left: 2px solid $light-blue;
            padding-left: 60px;
          }
          .presbytie-row {
            margin-top: 20px;
          }
        }
      }
      .button-row {
        .button {
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
    .button-row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      .button {
        position: relative;
        width: fit-content;
        background-color: #467CA0;
        color: $white;
        border-radius: 35px;
        cursor: pointer;
        font-family: $openSans-bold;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        &.button-prev {
          padding: 15px 25px 15px 50px;
          border: 2px solid $light-blue;
          background-color: $white;
          color: $primary_color;
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 18px;
            left: 20px;
            width: 16px;
            height: 16px;
            background-image: url("../../../images/arrow-up-blue.svg");
            background-size: contain;
            transform: rotate(270deg);
          }
        }
        &.button-next {
          padding: 15px 50px 15px 25px;
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 18px;
            right: 20px;
            width: 16px;
            height: 16px;
            background-image: url("../../../images/arrow-up-white.svg");
            background-size: contain;
            transform: rotate(90deg);
          }
        }
        &.button-print,
        &.button-reload-test {
          padding: 15px 25px;
          border: 2px solid $light-blue;
          background-color: $white;
          color: $primary_color;
        }
        &.button-take-rdv {
          padding: 15px 51px 15px 25px;
          &::after {
            content: '';
            position: absolute;
            display: block;
            top: 18px;
            right: 20px;
            width: 16px;
            height: 16px;
            background-image: url("../../../images/arrow-up-white.svg");
            background-size: contain;
            transform: rotate(90deg);
          }
        }
        &:disabled {
          opacity: 0.3;
          cursor: default;
        }
      }
    }
  }

  ul{
    &.form-stepper {
      counter-reset: section;
      margin-bottom: 50px;
      .form-stepper-circle {
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
      > li:not(:last-of-type) {
        margin-bottom: 10px;
      }
      li {
        a {
          .form-stepper-circle {
            &::after {
              content: '1';
              position: absolute;
              display: flex;
              top: 0;
              left: 50%;
              transform: translate(-50%,-50%);
              width: 70px;
              height: 70px;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              font-family: $openSans-bold;
              font-size: 28px;
              line-height: 38px;
              color: $light-blue;
              border: 4px solid $light-blue;
              border-radius: 50%;
              background-color: #E3F1FB;
            }
          }
        }
        &:nth-child(2) {
          a {
            .form-stepper-circle {
              &::after {
                content: '2';
              }
            }
          }
        }
        &:nth-child(3) {
          a {
            .form-stepper-circle {
              &::after {
                content: '3';
              }
            }
          }
        }
        &:nth-child(4) {
          a {
            .form-stepper-circle {
              &::after {
                content: '4';
              }
            }
          }
        }
        &:nth-child(5) {
          a {
            .form-stepper-circle {
              &::after {
                content: '5';
              }
            }
          }
        }
      }
      .form-stepper-active {
        .form-stepper-circle {
          &::after {
            color: #467CA0;
            border: 4px solid $white;
            background-color: $white;
            box-shadow: 0 3px 6px 0 rgba(0,0,0,0.16);
          }
        }
      }
      .form-stepper-unfinished {
        .form-stepper-circle {
          background-color: #f8f7ff;
        }
        .label {
          opacity: 0.5;
        }
      }
      .form-stepper-completed {
        .form-stepper-circle {
          &:after {
            color: $white;
            border: 4px solid #467CA0;
            background-color: #467CA0;
          }
        }
        &::after {
          border-bottom: 4px solid #467CA0 !important;
        }
      }
      .label {
        color: $primary_color;
        font-family: $openSans-bold;
        font-size: 17px;
        line-height: 23px;
        text-align: center;
        margin-top: 50px;
      }
      a {
        cursor: default;
      }
    }
  }

  .form-stepper-horizontal {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    li {
      position: relative;
      z-index: 1;
      flex: 0 1 20%;
      max-width: 20%;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: start;
      &:not(:last-child):after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 50%;
        display: block;
        height: 1px;
        width: 100%;
        border-bottom: 4px solid $light-blue;
      }
    }
    > li:not(:last-of-type) {
      margin-bottom: 0 !important;
    }
  }

}

.condition-popin-container {
  position: absolute;
  top: 25%;
  left: 50%;
  background: #153153;
  width: 50%;
  height: auto;
  padding: 15px;
  z-index: -1;
  visibility: hidden;
  transform: translate(0px, -50%);
  border-radius: 10px;
  .condition-popin-controls {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    .condition-popin-close {
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      color: #FFFFFF;
      font-weight: 900;
    }
  }
  .condition-popin-content-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .condition-popin-title {
      color: $white;
    }
    .condition-popin-content {
      padding: 35px;
      p {
        color: $white;
      }
    }
  }
}
.condition-popin-overlay {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: #999;
    transform: translate(-50%, 0);
    opacity: 0;
    visibility: hidden;
    transition: all 1s ease;
  }
}

.condition-popin-container[data-pop="pop-in"] {
  transform: translate(-50%, -50%) scale(0);
  transition: all .5s ease-in-out;
  &.show {
    transform: translate(-50%, -50%) scale(1);
    visibility: visible;
    z-index: 2;
    opacity: 1;
  }
}
.condition-popin-container[data-pop="pop-in"].show ~ .condition-popin-overlay::before {
  opacity: 0.5;
  visibility: visible;
  z-index: 1;
}

.print-element {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 30px 40px;
  .result-asmler-eyes {
    display: flex;
    .inner-result-asmler {
      flex: 0 1 50%;
      max-width: 50%;
      display: flex;
      padding: 40px;
      background-color: $white;
      .column-picture {
        display: none;
      }
      .column-content {
        padding-left: 0;
        .label {
          color: $primary_color;
          font-family: $openSans-bold;
          text-transform: capitalize;
          font-size: 20px;
          line-height: 27px;
          margin-bottom: 10px;
        }
        #result-test-asmler-left,
        #result-test-asmler-right {
          color: $primary_color;
          font-family: $openSans-regular;
          font-size: 16px;
          line-height: 24px;
        }
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  .result-global-eyes {
    margin-top: 35px;
    h3 {
      color: $primary_color;
      margin-bottom: 20px;
    }
    .info {

    }
    #result-global-eyes {

    }
  }
  .tests-result-row {
    display: flex;
    margin-top: 35px;
    margin-bottom: 35px;
    .results-row {
      flex: 0 1 50%;
      max-width: 50%;
      h3 {
        color: $primary_color;
        margin-bottom: 20px;
      }
      .label {
        color: $primary_color;
        font-family: $openSans-bold;
        margin-bottom: 25px;
      }
      &:last-child {
        border-left: 2px solid $light-blue;
        padding-left: 60px;
      }
      .presbytie-row {
        margin-top: 20px;
      }
    }
  }
  .button-row {
    display: none;
  }
}