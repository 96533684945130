.single-medecin{

  h1{
    margin-bottom: 21px;
    position: relative;

    &::after{
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 30px;
      height: 4px;
      background-color: #6BB2E3;
    }
  }

  h2{
    margin-bottom: 3px;
  }

  .line {
    margin-bottom: 50px;
  }

  .doctor{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin : 41px 0 41px 0;

    .picture{
      max-width: 200px;
      min-width: 200px;
      width: 20%;

      figure{

        img{
          border-radius: 50%;
          border: solid 3px #6BB2E3;
        }
      }
    }

    .doctor-profile{
      margin-left: 40px;
      width: 67%;

      p{
        color: #153153;
      }

      .rps-sector{
        display: flex;
        margin-bottom: 15px;

        p{
          color: #707070;
          &:last-child{
            margin-left: 40px;
          }
        }
      }
      .reasons{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;

        span{
          border-radius: 50px;
          background-color: #E3F1FB;
          font-size: 12px;
          color: #153153;
          padding: 3px 15px;
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
    }

    .appointment{
      position: relative;
      width: 30%;

      .rdv{
        padding: 40px;
        background-color: #F7F7F7;

        .content{
          display: flex;
          margin-bottom: 54px;

          svg{
            align-self: center;
          }

          .text-rdv{
            margin-left: 15px;

            .date{
              font-family: OpenSans-bold,sans-serif;
              white-space: nowrap;
            }

            p{
              color: #153153;

              &:first-child{
                white-space: nowrap;
              }
            }
          }
        }
        .cta-appointment{
          background-color: #467CA0;
          color: #ffffff;
          font-size: 16px;
          padding: 14px 23px;
          border-radius: 24px;
          line-height: 1;
          text-align: center;
          display: inline-block;
          width: 100%;

          &.disabled{
            background-color: #b5cad9;
            color: #dfeaf2;
            pointer-events: none;
          }
        }
      }
    }
  }

  .text-container{
    margin-bottom: 81px;

    p, li{
      color: #153153;
    }

    h3{
      margin: 25px 0;
    }

    ul {
      margin-top: 25px;

      li {
        list-style-type: disc;
        margin-left: 18px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1320px) {
  .single-medecin {
    .doctor {
      .appointment{
        width: 40%;
        margin-left: 50px;

        .rdv{
          .content{
            justify-content: center;
          }

          .cta-appointment{
            display: block;
          }
        }
      }
    }
  }
}