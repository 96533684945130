.home{
  .title-home{
    display: flex;
    flex-wrap: wrap;

    .partner{
      max-width: 173px;
      padding: 15px;
      background-color: #ffffff;
      border-radius: 10px;
      max-height: 83px;
      margin-right: 20px;

      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}