.block-video-player{
  .advgb-video-wrapper{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    #player{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .youtube-player-poster{
      overflow: hidden;
      opacity: 1;
      img{
        margin-top: -9%;
        width: 100%;
      }
    }
    .youtube-player-button{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      opacity: 1;
      background-size: auto;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
    }
  }
}