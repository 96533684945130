.block-text-with-img-no-color-background{
  .advgb-columns-container{
    .advgb-columns-row{
      .block-text-with-img-no-color-background-column-text{
        .advgb-column-inner{
          .block-text-with-img-no-color-background-title{
            margin-bottom: 30px;
            span{
              font-family: $openSans-bold;
            }
          }
          .block-text-with-img-no-color-background-content{
            line-height: 24px;
            color: #153153;
          }
        }
      }
      .block-text-with-img-no-color-background-column-img{
        .advgb-column-inner{
          .block-text-with-img-no-color-background-img{
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}