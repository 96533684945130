// Colors
$color-variations: (
  noir: #000000,
  blanc: #ffffff,
  content: (
    texte: #333333
  ),
  social: (
    /**
     * Brand colors
     *
     * @see https://brandcolors.net/
     */
    defaut: #9f9f9f,
    facebook: #3b5998,
    twitter: #1da1f2,
    google-plus: #dd4b39,
    linkedin: #0077b5,
    vimeo: #1ab7ea,
    youtube: #cd201f,
    instagram: unquote('radial-gradient(circle at 30% 100%, #fed276, rgba(253, 189, 97, 0.5) 9%, #f6804d 20%, rgba(232, 61, 92, 0)), linear-gradient(166deg, #4e60d3, #913baf 14%, #d52d88 76%, #f26d4f)')
  )
);

$white : #FFFFFF;
$black : #1d1d1d;

$primary_color: #153153;
$light-blue :#6BB2E3;

// Grid
$container-width: 1440px;
$column-gap: 12px;
$column-count: 12;
$breakpoints: (
  m: 1024px,
  s: 1023px,
  xs: 767px,
);

//Expert center
$center-main-color : #D6AF60;
$center-text-color : #153153;
$center-secondary-color : #D6AF6033;

// Font OpenSans

@font-face {
  font-family: OpenSans;
  src: local(OpenSans), url("fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"), url("fonts/OpenSans/OpenSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

$openSans-regular: 'OpenSans';

@font-face {
  font-family: OpenSans-light;
  src: local(OpenSans-Light), url("fonts/OpenSans/OpenSans-Light.woff2") format("woff2"), url("fonts/OpenSans/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

$openSans-light: 'OpenSans-light';

@font-face {
  font-family: OpenSans-bold;
  src: local(OpenSans-Bold), url("fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"), url("fonts/OpenSans/OpenSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

$openSans-bold: 'OpenSans-bold';

@font-face {
  font-family: OpenSans-semi-bold;
  src: local(OpenSans-SemiBold), url("fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2"), url("fonts/OpenSans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

$openSans-semi-bold: 'OpenSans-semi-bold';

@font-face {
  font-family: OpenSans-extra-bold;
  src: local(OpenSans-Extrabold), url("fonts/OpenSans/OpenSans-ExtraBold.woff2") format("woff2"), url("fonts/OpenSans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

$openSans-extra-bold: 'OpenSans-extra-bold';

$font-bold: 700;
