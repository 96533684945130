.centers-list-block {
  margin: 50px 0;
  .centers-list-container {
    .centers-list-row {
      .list-centers {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: stretch;
        .center-region {
          flex: 0 1 20%;
          padding: 0 5px;
          h3 {
            font-size: 16px;
            margin: 15px 0;
            color: $primary_color;
          }
          .centers-item {
            .center-item {
              padding-left: 20px;
              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}