* {
  margin: 0;
  padding: 0;
  border: none;
  -webkit-tap-highlight-color: transparent;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

img {
  border: none;
  display: block;
  outline: none;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  outline: none;
  border: none;
}

ul, li {
  list-style-type: none;
}

textarea {
  resize: vertical;
}

header, footer, nav, section, article {
  display: block;
}

html {
  font-size: 0;
  overflow-y: auto;
}

body {
  line-height: normal;
  width: 100%;
  height: auto;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

input, textarea, select, option {
  outline: none !important;
}
