.tab-three-column-block{
  //margin-bottom: 50px;
  position: relative;
  padding: 30px 0;
  .tab-three-column-block-container{
    .tab-three-column-block-columns-row{
      .tab-three-column-block-column{
        .tab-three-column-block-column-inner{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          .title-tab{
            font-family: $openSans-bold;
            color: #153153;
            margin-bottom: 20px;
            width: 100%;
          }
          .desc-tab{
            font-family: $openSans-regular;
            line-height: 24px;
            color: #153153 ;
            margin-bottom: 30px;
            width: 100%;
          }
          &.desktop-only{
            display: flex;
            .tab-three-column-block-column-one{
              width: 45%;
              border-radius: 10px;
              background-color: #FFF;
              box-shadow: 0 3px 15px #467ca033;
              .header-tab-one{
                background-color: #467CA0;
                color: #FFF;
                border-radius: 10px 10px 0 0;
                font-size: 20px;
                line-height: 27px;
                padding: 25px 35px;
                font-family: "OpenSans-bold";
              }
              .content-tab-one{
                line-height: 24px;
                padding: 20px 40px;
                color: #153153;
                position: relative;
                &:after{
                  content: '';
                  display: block;
                  width: calc(100% - 80px);
                  height: 1px;
                  opacity: 0.3;
                  background-color: #6BB2E3;
                  position: absolute;
                  bottom: 0;
                }
                &:last-child{
                  &:after{
                    display: none;
                  }
                }
              }
            }
            .tab-three-column-block-column-two,
            .tab-three-column-block-column-three{
              width: 25%;
              border-radius: 10px;
              background-color: #FFF;
              box-shadow: 0 3px 15px #467ca033;
              .header-tab-two,
              .header-tab-three{
                background-color: #33A0A0;
                color: #FFF;
                border-radius: 10px 10px 0 0;
                font-size: 20px;
                line-height: 27px;
                padding: 25px 20px;
              }
              .content-tab-two,
              .content-tab-three
              {
                line-height: 24px;
                padding: 20px 40px;
                color: #33A0A0;
                font-family: $openSans-bold;
                text-align: center;
                position: relative;
                &:after{
                  content: '';
                  display: block;
                  width: calc(100% - 80px);
                  height: 1px;
                  opacity: 0.3;
                  background-color: #6BB2E3;
                  position: absolute;
                  bottom: 0;
                }
                &:last-child{
                  &:after{
                    display: none;
                  }
                }
              }
            }
          }
          &.mobile-only{
            display: none;
          }
        }
      }
    }
  }
  &::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: #E3F1FB;
    transform: translate(-50%, 0);
  }
}