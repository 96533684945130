@media only screen and (max-width: 768px) {
  #content{
    padding-top: 0;
  }

  .container {
    padding: 10px;
  }

  .home{
    .titles{
      margin-top: 20px;
    }
  }

  /*****Block Home centres Trustville ******/
  .trustville-block{
    padding: 30px 0;
    .advgb-columns-container{
      .advgb-columns-row {
        .wp-block-advgb-column {
          padding: 0 20px 20px;
          margin-bottom: 0 !important;
          .advgb-column-inner{
            width: 100%;
            p{
              padding-right: 0;
            }
          }
          &.trustville-block-column-title{
            padding: 20px;
          }
        }
      }
      .trustville-block-container{
        flex-grow: 1 !important;
        .trustville-badge-stars {
          border: none;
          &.trustville-compact{
            margin-left: 0;
            min-width: 280px;
            width: 100%;
            .trustville-certificate {
              margin: 0;
            }
            #trustville_global_average {
              width: 160px;
              padding: 0 0 0 15px;
              margin-left: 0;
              .trustville-stars-small {
                height: 18px;
                width: 18px;
              }
              strong {
                margin-top: 5px;
              }
              .trustville-on-5 {
                margin-top: 5px;
              }
              .trustville-legend {
                margin: 35px 0 0 12px;
              }
            }
          }
        }
      }
      .trustville-block-column-text{
        &::before{
          display: none;
        }
      }
    }
  }

  /*****Block Home centres Iso-9001 ******/
  .iso-9001{
    padding: 30px 0;
    .advgb-columns {
      .wp-block-advgb-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        padding: 20px;
        margin-bottom: 0 !important;
        .advgb-column-inner{
          h2{
            text-align: center;
            font-size: 18px;
          }
          p{
            padding-right: 0;
          }
        }
      }
    }
    .iso-9001-column-text{
      padding-top: 0;
      &::before{
        display: none;
      }
    }
  }

  /*****Block Home Medecin ******/
  .medecin-block{
    .medecin-block-container{
      .medecin-block-row{
        flex-direction: column;
        .medecin-block-is-one-four{
          width: 100%;
          margin-bottom: 25px;
        }
      }
    }
  }

  /*****Block Title ******/
  .block-title{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column{
          .advgb-column-inner{
            h1{
              padding: 20px 0 20px 50px;
              margin-bottom: 20px;
              font-size: 20px;
              &::before{
                top: 30px;
              }
            }
          }
        }
      }
    }
  }

  /*****Block Text with img ******/
  .block-text-with-img{
    .advgb-columns-container{
      .advgb-columns-row{
        .block-text-with-img-column-text{
          margin: 0 !important;
          .advgb-column-inner{
            padding: 50px 30px;
            .block-text-with-img-title{
              font-size: 20px;
            }
            .block-text-with-img-content{
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .block-text-with-img-column-img{
          margin: 0 !important;
          .advgb-column-inner{
            .block-text-with-img-img{
              margin: 0 !important;
              img{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  /*****Block Text with column button ******/
  .block-text-with-column-button{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-is-one-third-tablet{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          align-content: center;
          flex-wrap: wrap;
        }
      }
    }
  }

  /*****Block img with content background ******/
  .block-img-with-content-background{
    .advgb-columns-container{
      .advgb-columns-row{
        .block-img-with-content-background-column-img{
          margin: 0 !important;
          .advgb-column-inner{
            padding: 10px 10px 0;
            .block-img-with-content-background-img{
              margin: 0;
            }
          }
        }
        .block-img-with-content-background-column-content{
          .advgb-column-inner{
            padding: 10px;
          }
        }
      }
    }
  }

  /*****Block FAQ ******/
  .block-faq{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column-inner{
          .wp-block-advgb-accordions{
            .advgb-accordion-item{
              .advgb-accordion-header{
                h4{
                  font-size: 14px;
                  line-height: 19px;
                  padding: 17px 20px;
                }
              }
              .advgb-accordion-body{
                p{
                  font-size: 14px;
                  line-height: 20px;
                  padding: 17px 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*****Block News ******/
  .news-block {

    .news {
      display: block;
      margin: 0;

      .news-item {
        width: 100%;
        margin: 0 0 30px 0;
        flex-direction: column;

        &:last-child{
          margin-bottom: 0;
        }

        figure{
          img{
            position: relative;
            height: auto;
            border-bottom-left-radius: 0;
            border-top-right-radius: 10px;
          }
        }

        .news-text, figure{
          width: auto;
        }
      }
    }
  }

  /***** Block First Appointment *****/
  .first-appointment-block {
    margin: 30px 0;
    .content-row {
      margin-left: 0;
      margin-bottom: 20px;
      flex: 0 1 100%;
    }
    .appointment{
      flex: 0 1 100%;
    }
  }

  /*****Single Post ******/
  .single-post{
    .similar{
      margin: 0;

      .posts{
        margin-bottom: 0;

        .item-post {
          width: 100%;
          margin-bottom: 50px;

          &:last-child{
            margin-top: 0;
          }
        }
      }
    }

    .head-article{
      .categories {
        flex-wrap: wrap;
        flex-direction: column;

        .categorie-item {
          width: 100%;
          margin-bottom: 5px;
        }
      }
    }

    .block-text-with-img{
      .advgb-column{
        .wp-block-image{
          img{
            position: initial;
          }
        }
      }
      .advgb-columns-container{
        .advgb-columns-row{
          .block-text-with-img-column-text{
            .advgb-column-inner{
              padding: 50px 0 0 0;
            }
          }
        }
      }
    }

    .block-double-cta{
      padding: 0;
      &.wp-block-advgb-columns{
        &.advgb-columns-wrapper{
          .advgb-columns-container{
            .advgb-columns {
              .wp-block-advgb-column{

                &:first-child {
                  .advgb-column-inner {
                    float: none;
                    margin: 0;
                  }
                }

                &:last-child {
                  .advgb-column-inner {
                    float: none;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*****Template Medecin ******/
  .post-type-archive-medecin {

    .line{
      display: none;
    }

    .title{
      margin-bottom: 20px;

      h1{
        font-size: 20px;
        margin-bottom: 0;
      }
    }

    h2{
      font-size: 20px;
    }

    h3,p{
      font-size: 14px;
    }

    .list-doctors {

      article {
        display: block;
        padding: 30px 21px;

        &:not(:last-child){
          margin-bottom: 10px;
        }
        .picture{
          margin-bottom: 21px;
          max-width: 130px;
          min-width: 130px;
        }

        .text-container{

          ul{
            padding-left: 18px;

            li{
              font-size: 14px;
            }
          }
        }

        .cta{

          &.detail{
            width: max-content;
            margin: 21px auto;
            font-size: 14px;
            padding: 14px 25px;
          }
        }
      }
    }
  }

  .single-medecin {

    .line{
      display: none;
    }

    h1{
      margin-top: 20px;
      font-size: 20px;

      &:after{
        bottom: -10px;
      }

    }

    h2{
      font-size: 16px;
    }

    p,li{
      font-size: 14px;
    }

    .doctor {
      margin: 0;

      .picture{
        max-width: 130px;
        min-width: 130px;
      }

      .doctor-profile {
        .specialty{
          margin-bottom: 5px;
        }

        P{
          text-align: center;
        }

        .rps-sector {
          flex-wrap: wrap;
          margin-bottom: 10px;

          p {
            width: 100%;

            &:last-child {
              margin-left: 0;
            }
          }
        }

        .reasons{
          margin: 12px 15% 0 15%;
        }
      }

      .appointment {
        padding: 0;

        .rdv {
          padding: 25px;

          .cta-appointment {
            padding-right: 25px;
            padding-left: 25px;
            width: max-content;
            font-size: 15px;
          }
        }
      }
    }

    .text-container{
      margin-top: 20px;
    }
  }

  /*****Blog ******/
  .blog{
    .sticky-post{
      .card{
        flex-direction: column;

        .one-half-part,.two-half-part{
          width: 100%;
        }
        .two-half-part{
          padding: 25px 10px 0 0;
        }
      }
    }
    .listing-blog{
      .filter-posts{
        .searchandfilter{
          ul{
            flex-direction: column;

            li{
              &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){
                width: 100%;
                margin-left: 0;
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
    .pagination{
      margin-bottom: 20px;
    }
  }

  /***************Footer**************/
  footer{
    .menu_footer{
      .footer-flex{
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-around;
        align-items: flex-start;
        padding: 0 20px;
        .menu-footer-menu-container{
          width: 100%;
          margin-bottom: 20px;
        }
      }

      .menu{
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
      }

      .networks{
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
      }

      .button-footer{
        width: 100%;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      .scrollUp{
        right: 20px;
      }
    }
  }

  /******** Block Centers List *******/
  .centers-list-block {
    .centers-list-container {
      .centers-list-row {
        .list-centers {
          .center-region {
            flex: 0 1 100%;
          }
        }
      }
    }
  }

}