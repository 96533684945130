.page-template-rdv-centers, .page-template-rdv-centers-search-result  {
  h1 {
    position: relative;
    text-align: center;
    font-size: 36px;
    padding: 40px 0 36px 50px;
    font-weight: 700;
    color: #153153;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      background-color: #e3f1fb;
      transform: translate(-50%, 0);
    }
  }
  .content {
    p {
      color: #153153;
    }
  }
  .map-container {
    margin-bottom: 0;
  }

  .rdv-search {
    display: grid;
    grid-template-columns: 32.584% 35% 14.917% 12%;
    grid-gap: 20px;
    padding: 26px 30px;
    background: #153153 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #467ca033;
    border-radius: 10px;

    .group-input {
      position: relative;
      height: 52px;
      .select-box,
      .txt-search {
        width: 100%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 15px #467ca033;
        padding: 15px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url(../../../images/Icon-ios-arrow-down-gray.svg);
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: 50%;
        color: #707070;
        font-size: 16px;
        min-height: 52px;

        &::placeholder,
        &:-ms-input-placeholder {
          color: #707070;
        }
      }

      .select-box,
      .btn-search {
        cursor: pointer;
      }

      .txt-search {
        background-image: none;
        padding-right: 34px;
        color: #153153;
      }

      img.icon-search {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
      }

      .error-message {
        color: #f00;
        position: absolute;
        font-size: 14px;
        bottom: -22px;
        left: 15px;
      }
    }

    .btn-search {
      background: transparent;
      border: 2px solid #ffffff;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 25px;
      cursor: pointer;
    }
  }

  .filter-bar {
    display: flex;
    margin: 15px 0;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    color: #153153;

    .title-h1 {
      font-size: 14px;
      padding-right: 5px;
      color: #153153;
    }

    .btn-filter {
      background: transparent;
      padding-top: 2px;
      cursor: pointer;
      color: #153153;

      &:hover,
      &:active {
        text-decoration: underline;
      }

      &:nth-child(2) {
        padding-right: 5px;
        margin-right: 5px;
        border-right: 1px solid #153153;
      }

      &.active {
        text-decoration: underline;
      }
    }
    &.mobile {
      display: none;
    }
  }

  button.go-back {
    margin-bottom: 59px;
    min-width: 131px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #6bb2e3;
    border-radius: 50px;
    padding: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #153153;
    font-size: 16px;
    font-weight: bold;

    &::before {
      content: "";
      display: block;
      background-image: url(../../../images/Icon-arrow-right.svg);
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
  }

  .loadmore {
    background: transparent;
    color: #153153;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    .rdv-search {
      padding: 20px 15px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      margin-bottom: 28px;
      justify-content: space-between;
      .group-input {
        width: 100%;
        &:nth-child(3) {
          width: 42.3%;
        }
        .select-box,
        .txt-search {
          font-size: 14px;
        }
      }
      .btn-search {
        width: calc(57.7% - 10px);
      }
    }

    .filter-bar {
      display: none;
      font-size: 12px;
      .btn-filter,
      .title-h1 {
        font-size: 12px;
      }
      &.mobile {
        display: block;
        grid-gap: 5px;
      }
    }
  }
}

/*
 * Style for Widget Alax
*/
.alax-widget-row {
  position: relative;
  #root {
    .container {
      display: flex !important;
      justify-content: center !important;
      align-items: center;
      flex-direction: column;
      flex-wrap: nowrap;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 95px;
  }
}
