.block-text-with-img{
  .advgb-columns-container{
    .advgb-columns-row{
      .block-text-with-img-column-text{
        background-color: #E3F1FB;
        .advgb-column-inner{
          padding: 90px 60px;

          .block-text-with-img-title{
            margin-bottom: 20px;
            span{
              font-family: $openSans-bold;
            }
          }
          .block-text-with-img-content{
            font-family: $openSans-regular;
            color: #153153;
            font-size: 20px;
            line-height: 35px;
          }
        }
      }
      .block-text-with-img-column-img{
        .advgb-column-inner{
          .block-text-with-img-img{
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .advgb-column-inner{
    height: 100%;
    figure{
      height: 100%;
    }
  }
}