.rdv-dispo-alaxione {
  position: relative;
  min-height: 450px;
  padding-top: 90px;
  .rdv-dispo-alaxione-title {
    position: relative;
    color: #ffffff;
    font-family: $openSans-bold;
    padding-left: 50px;
    margin-bottom: 10px;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 4px;
      left: 0;
      top: 24.5px;
      background-color: #6bb2e3;
    }
  }
  .rdv-dispo-alaxione-subtitle {
    color: #ffffff;
    font-family: $openSans-semi-bold;
    padding-left: 50px;
    margin-bottom: 45px;
    text-transform: none;
    font-size: 20px;
  }
  .rdv-search {
    display: grid;
    grid-template-columns: 32.584% 35% 14.917% 12%;
    grid-gap: 20px;
    margin-top: 45px;
    padding: 26px 30px;
    background: #e3f1fb 0 0 no-repeat padding-box;
    box-shadow: 0 3px 15px #467ca033;
    border-radius: 10px;

    .group-input {
      position: relative;
      .select-box,
      .txt-search {
        width: 100%;
        background: #ffffff 0 0 no-repeat padding-box;
        box-shadow: 0 3px 15px #467ca033;
        border-radius: 10px;
        padding: 15px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url(../../../images/Icon-arrow-down.svg);
        background-repeat: no-repeat;
        background-position-x: calc(100% - 15px);
        background-position-y: 50%;
        color: #707070;
        font-size: 16px;
        min-height: 52px;
      }

      .select-box,
      .btn-search {
        cursor: pointer;
      }

      .txt-search {
        background-image: none;
        padding-right: 34px;
      }

      img.icon-search {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;
      }

      .error-message {
        color: #f00;
        position: absolute;
        font-size: 14px;
        bottom: -22px;
        left: 15px;
      }

      .autocomplete-items {
        position: absolute;
        border-bottom: none;
        border-top: none;
        z-index: 99;
        top: 100%;
        left: 0;
        right: 0;
        font-size: 14px;
        max-height: 300px;
        overflow-x: hidden;
        overflow-y: auto;
        font-family: "OpenSans";
        color: #707070;
      }
      .autocomplete-items div {
        padding: 10px;
        cursor: pointer;
        background-color: #fff;
        border-bottom: 1px solid #ececec;
        min-height: 52px;
        line-height: 31px;
      }
      .autocomplete-items div:hover {
        /*when hovering an item:*/
        background-color: #e9e9e9;
      }
      .autocomplete-active {
        /*when navigating through the items using the arrow keys:*/
        background-color: DodgerBlue !important;
        color: #ffffff;
      }
    }

    .btn-search {
      background: #467ca0;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 25px;
      cursor: pointer;
    }
  }
  .row {
    .cards {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 15px;
      .card {
        background: white;
        flex: 0 1 20%;
        max-width: 237px;
        margin-bottom: 50px;
        border-radius: 10px;
        padding: 50px 15px 20px 15px;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-content: center;
        -webkit-transition: 0.3s transform ease-out;
        transition: 0.3s transform ease-out;
        .card-motif-picto {
          margin-bottom: 30px;
          width: 60px;
        }
        .card-motif-title {
          font-family: $openSans-bold;
          color: #153153;
          margin-bottom: 5px;
          font-size: 18px;
        }
        .card-motif-description {
          font-family: $openSans-regular;
          font-size: 14px;
          color: #707070;
          min-height: 70px;
        }
        .card-motif-next-rdv {
          background-color: #f7f7f7;
          border-radius: 10px;
          padding: 13px 16px;
          margin-bottom: 15px;
          max-width: calc(100% + 30px);

          .card-motif-label-next-rdv {
            font-family: $openSans-regular;
            color: #467ca0;
            font-size: 12px;
          }
          .card-motif-date-next-rdv {
            font-family: $openSans-bold;
            font-size: 14px;
            color: #153153;
            white-space:nowrap;
          }
        }
        .card-motif-take-rdv-link {
          .card-motif-take-rdv-link-inner {
            display: block;
            background-color: #467ca0;
            border-radius: 50px;
            padding: 14px 18px;
            font-family: $openSans-bold;
            font-size: 16px;
            color: #ffffff;
            line-height: 1;
            &:hover{
              background-color: #153153;
            }
            &.disabled {
              background-color: #b5cad9;
              color: #dfeaf2;
              cursor: default;
              &:hover {
                background-color: #b5cad9;
                color: #dfeaf2;
              }
            }
            &.external-link {
              position: relative;
              padding-left: 10px;
              padding-right: 28px;
              &::after {
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translate(0, -50%);
                background-image: url("../../../images/blank.png");
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }

        .show-children-motifs-button {
          display: block;
          background-color: #467ca0;
          border-radius: 50px;
          padding: 14px 18px;
          font-family: $openSans-bold;
          font-size: 16px;
          color: #ffffff;
          line-height: 1;
          &:hover{
            background-color: #153153;
          }
        }
        .children-motifs-popin-container {
          position: absolute;
          top: 50%;
          left: 0;
          background: #153153;
          width: 100%;
          height: auto;
          padding: 15px;
          z-index: -1;
          visibility: hidden;
          transform: translate(0px, -50%);
          border-radius: 10px;
          .children-motifs-popin-controls {
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            .children-motifs-popin-close {
              cursor: pointer;
              text-align: center;
              font-size: 16px;
              color: #FFFFFF;
              font-weight: 900;
            }
          }
          .children-motifs-popin-content {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 15px;
          }
        }
        .children-motifs-popin-overlay {
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100vw;
            z-index: -1;
            background-color: #999;
            transform: translate(-50%, 0);
            opacity: 0;
            visibility: hidden;
            transition: all 1s ease;
          }
        }

        .children-motifs-popin-container[data-pop="pop-in"] {
          transform: translate(0px, -50%) scale(0);
          transition: all .5s ease-in-out;
          &.show {
            transform: translate(0px, -50%) scale(1);
            visibility: visible;
            z-index: 2;
            opacity: 1;
          }
        }
        .children-motifs-popin-container[data-pop="pop-in"].show ~ .children-motifs-popin-overlay::before {
          opacity: 0.5;
          visibility: visible;
          z-index: 1;
        }

        &:hover,
        &:focus,
        &:active {
          -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
        }
        &.has-children {
          &:hover,
          &:focus,
          &:active {
            -webkit-transform: none;
            transform: none;
          }
        }
        &.custom-card {
          justify-content: flex-start;
          position: relative;
          .card-motif-take-rdv-link {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 40px);
          }
        }
      }
      .card-mobile {
        display: none;
      }
      .card-children-motifs-container {
        display: none;
      }
      &.col-4-cards {
        .card {
          flex: 0 1 20%;
          max-width: none;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      background: var(--c-pv-header-background);
      background-size: cover;
      transform: translate(-50%, 0);
    }
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: #153153;
    opacity: 0.8;
    transform: translate(-50%, 0);
  }
}
@media (max-width: 1024px) {
  .rdv-dispo-alaxione {
    .rdv-search {
      padding: 20px 15px;
      display: flex;
      flex-wrap: wrap;
      grid-gap: 10px;
      justify-content: space-between;
      .group-input {
        width: 100%;
        &:nth-child(3) {
          width: 42.3%;
        }
        .select-box,
        .txt-search {
          font-size: 14px;
          
        }
      }
      .btn-search {
        width: calc(57.7% - 10px);
      }
    }
  }
}
