.block-text-with-column-button{
  .advgb-columns-container{
    .advgb-columns-row{
      align-items: center;
      .advgb-column-inner{
        h2{
          margin-bottom: 15px;
        }
        p{
          line-height: 24px;
        }
        .button-white{
          a{
            &:hover{
              border-color: #153153 !important;
            }
          }
        }
      }
    }
  }
}