.block-title-text{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          h2{
            margin-bottom: 15px;
          }
          p{
            line-height: 30px;
            margin-bottom: 20px;
            color: #153153;
          }
          ul, ol {
            font-family: $openSans-regular;
            color : #153153;
            margin-bottom: 20px;
            li {
              font-size: 16px;
              line-height: 24px;
              position: relative;
              padding: 5px 5px 5px 20px;
              margin-left: 0;
              &::before {
                content: '';
                height: 6px;
                width: 6px;
                background: #153153;
                color: #153153;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 15px;
                margin: 0;
                padding: 0;
              }
            }
          }

          ol {

            li {
              padding: 5px 5px 5px 0;
              list-style-type: decimal;
              list-style-position: inside;

              &::before {
                content: none;
              }

              ol {

                li {
                  margin: 5px 0 0 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}