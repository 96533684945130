body{
  &.expert{
    header{
      .flex-container {
        &.row{
          &.prehead{
            background-color: $center-main-color;

            .container{
              .row{
                .label{
                  p, a{
                    color: $center-text-color;
                    font-weight: 600;
                  }
                  &:nth-child(1){
                    p{
                      &::before{
                        background: url("../../images/expert/pin-expert.svg") no-repeat;
                      }
                    }
                  }
                  &:nth-child(2){
                    p{
                      &::before{
                        background: url("../../images/expert/clock-expert.svg") no-repeat;
                        background-size: contain;
                      }
                    }
                  }
                  &:nth-child(3){
                    p{
                      &::before{
                        background: url("../../images/expert/secteur-card-expert.svg") no-repeat;
                        background-size: contain;
                      }
                    }
                  }
                  &:nth-child(4){
                    a{
                      &::before{
                        background: url("../../images/expert/phone-expert.svg") no-repeat;
                      }
                    }
                  }
                }
              }
            }
          }
          &.head{
            .row-contact-header{
              .first-button-header{
                #inner-first-button-header{
                  border-color: $center-main-color;
                  &:hover{
                    color: $center-text-color;
                  }
                }
              }
              .second-button-header{
                border-color: $center-main-color;
                &:hover{
                  background: $center-main-color;
                }
              }
            }
          }
          .nav{
            ul{
              &.sub-menu{
                li{
                  a{
                    &:hover{
                      &:before{
                        left: 20px;
                        width: calc(100% - 20px);
                        top: 35px;
                      }
                    }
                  }
                }
              }
              li{
                a{
                  &:before{
                    background-color: $center-main-color;
                    transition: none;
                  }
                  &:hover{
                    color: $center-text-color;

                    &:before{
                      transform: none;
                      transition: none;
                      background-color: $center-main-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .title-line{
      &:before{
        background-color: $center-main-color;
      }
    }
    .wp-block-advgb-columns{
      .advgb-columns-1{
        .wp-block-advgb-button{
          .wp-block-advgb-button_link{
            border-color: $center-main-color!important;
            background-color: $center-main-color!important;
            color: $center-text-color!important;

            &:hover{
              border-color: $center-text-color!important;
              background-color: $center-text-color!important;
              color: #ffffff!important;
            }
          }
        }
      }
    }
    .block-text-with-column-button{
      .button-blue{
        a{
          border-color: $center-main-color!important;
          background-color: $center-main-color!important;
          color: $center-text-color!important;

          &:hover{
            border-color: $center-text-color!important;
            background-color: $center-text-color!important;
            color: #ffffff!important;
          }
        }
      }
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column-inner{
            .button-white{
              a{
                border-color: $center-main-color!important;

                &:hover{
                  border-color: $center-main-color!important;
                  background-color: $center-main-color!important;
                  color: #ffffff!important;
                }
              }
            }
          }
        }
      }
    }
    .block-double-cta{
      .wp-block-advgb-column{
        &:first-child{
          .wp-block-advgb-button{
            .wp-block-advgb-button_link{
              border-color: $center-main-color!important;

              &:hover{
                border-color: $center-main-color!important;
                background-color: $center-main-color!important;
                color: #ffffff!important;
              }
            }
          }
        }
        &:last-child{
          .wp-block-advgb-button{
            .wp-block-advgb-button_link{
              border-color: $center-main-color!important;
              background-color: $center-main-color!important;
              color: $center-text-color!important;

              &:hover{
                border-color: $center-text-color!important;
                background-color: $center-text-color!important;
                color: #ffffff!important;
              }
            }
          }
        }
      }
    }
    .block-quote{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column-inner{
            .block-quote-text{
              &:before{
                background: url("../../images/expert/quote-expert.svg") no-repeat;
              }
            }
          }
        }
      }
    }
    .rdv-dispo-alaxione{
      .rdv-dispo-alaxione-title{
        &:before{
          background-color: $center-main-color;
        }
      }
      .row{
        .cards{
          .card{
            .card-motif-take-rdv-link{
              .card-motif-take-rdv-link-inner {
                background-color: $center-main-color;

                &:hover{
                  background-color: $center-text-color;
                }
                &.disabled{
                  background-color: #d0be99;
                }
              }
            }
            .show-children-motifs-button {
              background-color: $center-main-color;
              &:hover{
                background-color: $center-text-color;
              }
              &.disabled{
                background-color: #d0be99;
              }
            }
            .card-motif-next-rdv{
              .card-motif-label-next-rdv{
                color: $center-main-color;
              }
            }
          }
        }
      }
    }
    .wrapper-container {
      .main-title {
        &:before {
          background: $center-main-color 0 0 no-repeat padding-box;
        }
      }
      .map-container{
        .mapboxgl-map{
          .popup-text{
            .appointment{
              .underlined-link{
                background-color: $center-main-color;
                color: $center-text-color;
                &:hover{
                  background-color: #153153;
                  color: #ffffff;
                }
                &.disable{
                  background-color: #d0be99;
                  cursor: default;
                  pointer-events: none;
                }
              }
            }
          }
        }
        .right-column{
          .items{
            .item{
              .typology{
                a{
                  background-color: $center-secondary-color;
                }
              }
              .content-bottom{
                .underlined-link{
                  background-color: #D6AF60;
                  color: $center-text-color;

                  &.disable{
                    background-color: #d0be99;
                    cursor: default;
                    pointer-events: none;
                  }

                  &:hover{
                    background-color: $center-text-color;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .news-block{
      .news{
        .news-item{
          .news-text{
            .cta{
              a{
                &:before{
                  background-image: url("../../images/expert/arrow-expert.svg");
                }
                &:after{
                  background-color: $center-main-color;
                }
                &:hover{
                  &:after{
                    background-color: $center-text-color;
                  }
                }
              }
            }
          }
        }
      }
      .news-cta{
        a{
          background-color :$center-main-color;
          color: $center-text-color;
          font-family: 'OpenSans-bold';
          &:hover{
            background-color : $center-text-color;
            color: #ffffff;
          }
        }
      }
    }
    .container{
      &.detail{
        .similar{
          .posts{
            .item-post{
              .cta{
                a{
                  &:before{
                    background-image: url("../../images/expert/arrow-expert.svg");
                  }
                  &:after{
                    background-color: $center-main-color;
                  }
                  &:hover{
                    &:after{
                      background-color: $center-text-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .block-come-on-center{
      .advgb-columns-container{
        .advgb-columns-row{
          .wp-block-advgb-column{
            .advgb-column-inner{
              .block-come-on-center-adress{
                &:before{
                  background: url("../../images/expert/pin-expert-gold.svg") no-repeat;
                  background-size: contain;
                }
              }
              .block-come-on-center-tel {
                &:before{
                  background: url("../../images/expert/phone-expert-gold.svg") no-repeat;
                  background-size: contain;
                }
              }
              .block-come-on-center-mail{
                &:before{
                  background: url("../../images/expert/contact-expert-gold.svg") no-repeat;
                  background-size: contain;
                }
              }
              .block-come-on-center-text{
                &:before{
                  background: url("../../images/expert/calendar-expert-gold.svg") no-repeat;
                  background-size: contain;
                }
              }
              .block-come-on-center-button{
                a{
                  background-color: $center-main-color;
                  color: $center-text-color;

                  &:before{
                    background: url("../../images/expert/comment-venir-expert.svg") no-repeat;
                  }

                  &:hover{
                    background-color: #467ca0;
                    color: #ffffff;

                    &:before{
                      background-image: url("../../images/Comment-venir.svg");
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .trustville-block{
      .advgb-columns-container{
        .advgb-columns-row{
          .wp-block-advgb-column{
            .advgb-column-inner{
              .trustville-link{
                a{
                  border-bottom: 3px solid $center-main-color!important;

                  &:hover{
                    border-bottom: 3px solid $center-text-color!important;
                  }

                  &:after{
                    background-image: url("../../images/expert/arrow-expert.svg");
                  }
                }
              }
            }
          }
        }
      }
    }
    .block-medecin {
      .advgb-columns-container {
        .advgb-columns-row {
          .advgb-column {
            .advgb-column-inner {
              .block-medecin-title {
                &::before {
                  background-color: $center-main-color;
                }
              }
            }
          }
        }
      }
    }

    .medecin-block{
      .medecin-block-container{
        .medecin-block-link{
          .medecin-block-link-inner{
            background-color: $center-main-color;
            color: $center-text-color;
            &:hover{
              background-color: $center-text-color;
              color: #ffffff;
            }
          }
        }
        .medecin-block-row{
          .medecin-block-is-one-four{
            &:hover{
              .medecin-block-inner{
                .medecin-block-inner-row{
                  .medecin-block-img-column{
                    .medecin-block-image-block{
                      box-shadow: 0 0 0 10px $center-secondary-color;
                    }
                  }
                }
              }
            }
            .medecin-block-inner{
              .medecin-block-inner-row{
                .medecin-block-img-column{
                  .medecin-block-image-block{
                    box-shadow: 0 0 0 3px $center-main-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    .block-img-with-content-background-column-content{
      .advgb-column-inner{
        .wp-block-advgb-button .wp-block-advgb-button_link{
          border-bottom: 3px solid $center-main-color !important;

          &:after{
            background-image: url("../../images/expert/arrow-expert.svg")!important;
          }
        }
      }
    }
    .block-title-border-bottom{
      .advgb-columns-container{
        .advgb-columns-row{
          .advgb-column{
            .advgb-column-inner{
              h2{
                &:before{
                  background-color: $center-main-color;
                }
              }
            }
          }
        }
      }
    }
    .iso-9001{
      .iso-9001-column-text{
        .iso-9001-link{
          a{
            border-bottom: 3px solid $center-main-color !important;
            &:hover{
              border-bottom: 3px solid $center-text-color !important;
            }
            &:after{
              background-image: url("../../images/expert/arrow-expert.svg");
            }
          }
        }
      }
    }
    .block-title {
      .advgb-columns-container {
        .advgb-columns-row {
          .advgb-column {
            .advgb-column-inner {
              h1,h2,h3,h4 {
                &:before {
                  background-color: $center-main-color;
                }
              }
            }
          }
        }
      }
    }

    &.post-type-archive-medecin{
      .title{
        h1{
          &:before{
            background-color: $center-main-color;
          }
        }
      }
      .list-doctors{
        article{
          .cta{
            &.detail{
              background-color: $center-main-color;
              color: $center-text-color;
            }
          }
          .picture{
            figure{
              img{
                border-color: $center-main-color;
              }
            }
          }
        }
      }
    }
    &.single-medecin{
      h1{
        &:after{
          background-color: $center-main-color;
        }
      }
      .doctor{
        .appointment{
          .rdv{
            .cta-appointment{
              background-color: $center-main-color;
              color: $center-text-color;
              &.disabled{
                background-color: #d0be99;
                color: rgba(21, 49, 83, 0.4);
              }
            }
            .content{
              svg{
                .a{
                  fill: $center-main-color;
                }
              }
            }
          }
        }
        .doctor-profile{
          .reasons{
            span{
              background-color: $center-secondary-color;
            }
          }
        }
        .picture{
          img{
            border-color: $center-main-color;
          }
        }
      }
    }
    &.page-template-centers{
      h1{
        &:before{
          background-color: $center-main-color;
        }
      }
    }
    footer{
      .menu_footer{
        .scrollUp{
          &:hover{
            background-color: $center-main-color;
          }
          .icon-arrow-up{
            background-image: url("../../images/expert/arrow-up-expert.svg");
          }
        }
        .button-footer{
          a{
            background-color: $center-main-color;
            border: 2px solid $center-main-color;
            color: $center-text-color;

            &:hover{
              background-color: $center-text-color;
              border: 2px solid $center-main-color;
              color: $center-main-color;
            }
          }
        }
      }
    }
  }
}

