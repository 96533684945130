.block-quote{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column-inner{
        .block-quote-text{
          position: relative;
          padding-top: 70px;
          line-height: 24px;
          &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            bottom: 0;
            right: 0;
            display: block;
            background: url("../../../images/quote.svg") no-repeat;
            width: 45px;
            height: 35px;
            background-size: contain;
            transform: translate(-50%, 0);
          }
        }
      }
    }
  }
}