.block-faq{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column-inner{
        .wp-block-advgb-accordions{
          .advgb-accordion-item{
            .advgb-accordion-header{
              border-radius: 10px !important;
              display: flex;
              flex-direction: row-reverse;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: space-between;
              align-items: center;
              h3,h2{
                font-size: 20px;
                line-height: 27px;
                padding: 17px 20px;
              }
              .advgb-accordion-header-icon {
                margin-right: 0 !important;
              }
            }
            .advgb-accordion-header[aria-expanded="true"]{
              border-radius: 10px 10px 0 0 !important;
              .advgb-accordion-header-icon {
                display: block;
                position: relative;
                &::before{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: -20px;
                  display: block;
                  width: 20px;
                  height: 2px;
                  background-color: #467CA0;
                }
                svg{
                  display: none;
                }
              }
            }
            .advgb-accordion-body{
              border-radius: 0 0 10px 10px !important;
              p{
                color: #467CA0;
                padding: 17px 20px;
              }
            }
          }
        }
      }
    }
  }
}