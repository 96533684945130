.page-template-centers{
  h1{
    position: relative;
    font-size: 36px;
    margin: 40px 0 36px 50px;
    font-weight: 700;
    color: #153153;

    &::before{
      content: "";
      position: absolute;
      left: -50px;
      top: 50%;
      background-color: #6BB2E3;
      height: 4px;
      width: 30px;
    }
  }
  .line{
    height: 1px;
    width: 100%;
    display: block;
    opacity: 0.3;

    &.blue{
      background-color: #6BB2E3;
    }
  }
  .content{
    margin: 36px 0 28px 0;

    p{
      color: #153153;
    }
  }
  .map-container{
    margin-bottom: 0;
  }
}