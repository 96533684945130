.block-img-with-content-background{
  background-color: #F7F7F7;
  border-radius: 10px;
  .advgb-columns-container{
    .advgb-columns-row{
      .block-img-with-content-background-column-img{
        .advgb-column-inner{
          padding: 44px 0 44px 40px;
          img{
            max-height: 180px;
          }
        }
      }
      .block-img-with-content-background-column-content{
        .advgb-column-inner{
          padding: 44px 60px 0 0;
          .block-img-with-content-background-title{
            margin-bottom: 15px;
          }
          .block-img-with-content-background-content{
            line-height: 24px;
            margin-bottom: 15px;
          }
          .wp-block-advgb-button .wp-block-advgb-button_link{
            border-bottom: solid #6BB2E3;
            border-width: 3px!important;
            position: relative;

            &:hover{
              border-bottom: 3px solid #17385e !important;
              box-shadow: none;

              &:after{
                filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
              }
            }

            &:after{
              content: "";
              display: block;
              width: 19px;
              height: 18px;
              position: absolute;
              top: 9px;
              right: 0;
              background-image: url("../../../images/cta-arrow-right.svg");
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
}