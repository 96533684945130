.block-title{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          h1,h2,h3,h4{
            font-family: $openSans-bold;
            color: #153153;
            padding: 38px 0 42px 50px;
            position: relative;
            &:before{
              content: "";
              display: block;
              width: 30px;
              height: 4px;
              background-color: #6bb2e3;
              position: absolute;
              top: 50%;
              left: 0;
            }
          }
        }
      }
    }
  }
}