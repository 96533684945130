@media only screen and (max-width: 1024px) {
  /***************Header**************/
  header{
    .flex-container.row {
      &.prehead{
        display: none;
      }
      &.head{
        padding: 0;
        .container{
          padding: 10px 20px 10px 10px;
          display: flex;
          margin: 0 auto;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          align-items: center;
          justify-content: space-between;

          .row-logo-header {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: 0;
            min-width: unset;
            margin-right: 0;
            flex-direction: row;
            align-items: center;
            align-content: center;
            width: auto;
            max-width: none;
          }
        }
        .row-contact-header{
          display: none;
          .first-button-header{
            padding: 0;
            align-self: center;
            margin-right: 40px;
            #inner-first-button-header{
              border-bottom: 2px solid #6BB2E3;
              font-family: $openSans-bold;
              font-size: 16px;
              text-align: center;
              padding: 8px 0;
              color: $primary_color;
            }
            &:hover{
              #inner-center-button-header{
                border-bottom: solid 2px #a8acb2;
                color: #a8acb2;
              }
            }
          }
          .second-button-header{
            #inner-second-button-header{
              padding: 13px 65px 13px 90px;
            }
            &:hover{

            }
          }
        }
      }
      .nav{
        display: none;
        .menu{
          max-width: $container-width;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: flex-start;
        }
        ul {
          li {
            margin: 0 30px;
          }
        }
      }
      .menu-button-mobile{
        display: flex;
        font-size: 16px;
        font-weight: bold;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        color: $primary_color;
        text-align: left;
        position: relative;
        &::before{
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          bottom: -5px;
          background: #6bb2e3;
        }
      }
    }
  }

  /************Menu-Mobile*************/
  .show{
    display: block !important;
    font-family: $openSans-regular;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 77px;
    left: 55px;
    padding-left: 10px;
    color: #173150;
    z-index: 9999;
    &:before{
      content: " ";
      top: 25%;
      left: -20px;
      width: 15px;
      height: 15px;
      position: absolute;
      background-image: url("../../images/cta-arrow-right.svg");
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
  }
  .show-link{
    display: block !important;
    font-family: $openSans-regular;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    top: 65px;
    left: 60px;
    padding-left: 10px;
    color: #173354;
    z-index: 9999;
  }
  body.menu-mobile-open{
    background-color: #E3F1FB;
    overflow: visible;
    header{
      .head{
        justify-content: flex-end;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: flex-end;
        padding: 10px 0 !important;
        background-color: #e3f1fb !important;
        .container{
          justify-content: flex-end !important;
          .nav{
            position: absolute;
            top: 85px;
            left: 0;
            width: 100%;
            text-align: left;
            max-width: none;
            .menu{
              .menu-item-has-children{
                position: relative;
                &:after{
                  content: "";
                  display: block;
                  top: 14px;
                  right: -30px;
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  background-image: url("../../images/cta-arrow-right.svg");
                  background-repeat: no-repeat;
                  pointer-events: none;
                }
                &.clicked{
                  position: unset !important;
                  .sub-menu{
                    display: block;
                    position: absolute;
                    z-index: 999;
                    background-color: #e3f1fb;
                    width: 100vw;
                    max-width: none;
                    height: 100vh;
                    top: 0;
                    left: 0;
                    text-align: left;
                    padding-top: 30px;
                    &:before{
                      content: '';
                      display: block;
                      width: calc(100% - 10px);
                      height: 1px;
                      opacity: 0.3;
                      background-color: #6BB2E3;
                      position: absolute;
                      top: 20px;
                      left: 5px;
                    }
                  }
                }
              }
            }
          }
          .row-contact-header{
            position: absolute;
            top: 215px;
            left: 0;
            display: flex;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            .first-button-header{
              margin-bottom: 20px;
              padding: 0;
              align-self: flex-start;
              margin-right: 0;
              margin-left: 30px;
            }
            .second-button-header{
              background: white;
            }
          }
        }
      }
    }
  }

  /*****Block Home centres Alaxione *******/
  .rdv-dispo-alaxione{
    position: relative;
    padding-top: 20px;
    .rdv-dispo-alaxione-title{
      font-size: 20px;
      &::before{
        left: 5px;
        top: 13.5px;
      }
    }
    .rdv-dispo-alaxione-subtitle{
      font-size: 14px;
    }
    .row{
      position: relative;
      z-index: 50;
      .label-mobile{
        font-family: $openSans-semi-bold;
        font-size: 14px;
        line-height: 21px;
        padding: 15px 0 0 0;
        color: $primary_color;
      }
      .cards{
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px 0;
        .card{
          display: none;
        }
        .card-mobile {
          display: flex;
          background: white;
          border-radius: 10px;
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          padding: 20px 15px;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          text-align: left;
          height: auto;
          justify-content: flex-start;
          align-content: center;
          .card-motif-take-rdv-link-inner{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            .column-picto{
              flex: 0 1 20%;
              max-width: 20%;
              .card-motif-picto{
                width: 50px;
                min-width: 50px;
                margin-right: 20px;
              }
            }
            .column-text{
              flex: 0 1 66%;
              max-width: 66%;
              .card-motif-title{
                font-family: $openSans-bold;
                color: $primary_color;
                margin-bottom: 5px;
                font-size: 16px;
                line-height: normal;
              }
              .card-motif-description{
                font-family: $openSans-regular;
                font-size: 10px;
                color: #707070;
                line-height: 14px;
              }
              .card-motif-next-rdv {
                margin-top: 15px;
                .card-motif-label-next-rdv {
                  font-family: $openSans-regular;
                  font-size: 10px;
                  color: #707070;
                  line-height: 14px;
                }
                .card-motif-date-next-rdv {
                  font-family: $openSans-regular;
                  font-size: 13px;
                  color: $primary_color;
                  line-height: 14px;
                }
              }
            }
            &.external-link-mobile {
              position: relative;
              &::after {
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                position: absolute;
                top: 50%;
                right: 8px;
                transform: translate(0, -50%);
                background-image: url("../../images/blank_mobile.png");
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
            &.disabled {
              opacity: 0.35;
            }
          }
          &.has-children {
            .card-motif-toggle-children-motifs {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              position: relative;
              .column-picto{
                flex: 0 1 20%;
                max-width: 20%;
                .card-motif-picto{
                  width: 50px;
                  min-width: 50px;
                  margin-right: 20px;
                }
              }
              .column-text{
                flex: 0 1 66%;
                max-width: 66%;
                .card-motif-title{
                  font-family: $openSans-bold;
                  color: $primary_color;
                  margin-bottom: 5px;
                  font-size: 16px;
                  line-height: normal;
                }
                .card-motif-description{
                  font-family: $openSans-regular;
                  font-size: 10px;
                  color: #707070;
                  line-height: 14px;
                }
                .card-motif-next-rdv {
                  margin-top: 15px;
                  .card-motif-label-next-rdv {
                    font-family: $openSans-regular;
                    font-size: 10px;
                    color: #707070;
                    line-height: 14px;
                  }
                  .card-motif-date-next-rdv {
                    font-family: $openSans-regular;
                    font-size: 13px;
                    color: $primary_color;
                    line-height: 14px;
                  }
                }
              }
              &::after {
                content: ">";
                position: absolute;
                top: 0;
                right: 0;
                width: 15px;
                height: 15px;
                transform: rotate(90deg);
                font-size: 15px;
                color: #0A246A;
              }
            }
          }
        }
        .card-children-motifs-container {
          display: none;
          .card-child-motif-inner {
            display: flex;
            background: white;
            border-radius: 10px;
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            padding: 20px 15px;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            text-align: left;
            height: auto;
            justify-content: flex-start;
            align-content: center;
            .card-motif-take-rdv-link-inner{
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              justify-content: space-around;
              align-items: center;
              width: 100%;
              .column-picto{
                flex: 0 1 20%;
                max-width: 20%;
                .card-motif-picto{
                  width: 50px;
                  min-width: 50px;
                  margin-right: 20px;
                }
              }
              .column-text{
                flex: 0 1 66%;
                max-width: 66%;
                .card-motif-title{
                  font-family: $openSans-bold;
                  color: $primary_color;
                  margin-bottom: 5px;
                  font-size: 16px;
                  line-height: normal;
                }
                .card-motif-description{
                  font-family: $openSans-regular;
                  font-size: 10px;
                  color: #707070;
                  line-height: 14px;
                }
                .card-motif-next-rdv {
                  margin-top: 15px;
                  .card-motif-label-next-rdv {
                    font-family: $openSans-regular;
                    font-size: 10px;
                    color: #707070;
                    line-height: 14px;
                  }
                  .card-motif-date-next-rdv {
                    font-family: $openSans-regular;
                    font-size: 13px;
                    color: $primary_color;
                    line-height: 14px;
                  }
                }
              }
              &.disabled {
                opacity: 0.35;
              }
            }
          }
        }
        .card-children-motifs-container[aria-expanded=true] {
          display: block;
          width: 100%;
        }
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100vw;
        z-index: -1;
        transform: translate(-50%);
        background-color: #E3F1FB;
      }
    }
    .rdv-search {
      #geo-search {
        min-height: 55px;
      }
      .group-input {
        .mapboxgl-ctrl-geocoder {
          .mapboxgl-ctrl-geocoder--icon-search {
            top: 25px !important;
          }
          .mapboxgl-ctrl-geocoder--input {
            border-radius: 10px;
            padding: 27px 50px 27px 15px;
          }
        }
      }
    }
    &::before{
      height: 90%;
    }
    &::after{
      height: 90%;
    }
  }

  /*****Block Home centres Come on Center ******/
  .block-come-on-center{
    &.advgb-columns-wrapper .advgb-columns-container .advgb-columns{
      display: block!important;
    }
    .advgb-columns-container{
      .advgb-columns-row{
        .wp-block-advgb-column{
          flex-grow: 0 !important;
          flex-basis: unset !important;
          .advgb-column-inner{
            overflow: hidden;
            .block-come-on-center-title{
              padding-left: 0;
              font-size: 18px;
            }
            .block-come-on-center-tel{
              margin-bottom: 45px;
              margin-right: 45px;

              &::after{
                bottom: -50px;
              }
            }
            .block-come-on-center-mail{
              display: inline-block;
              margin-bottom: 0;
              margin-top: -30px;
              float: none;
            }
            .block-come-on-center-text{
              padding: 0 45px;
              margin: 30px 0;
              position: relative;
              &::after{
                content: '';
                display: block;
                position: absolute;
                bottom: -30px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #6bb2e3;
              }
            }
            .block-come-on-center-button{
              padding: 0 45px;
              a{
                margin-top: 30px !important;
              }
            }
            .block-come-on-center-map{
              display: none;
            }
          }
        }
      }
    }
  }

  /*******Block Tab 3 column**********/
  .tab-three-column-block{
    .tab-three-column-block-container{
      .tab-three-column-block-columns-row{
        .tab-three-column-block-column{
          .tab-three-column-block-column-inner{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            .title-tab{
              margin-bottom: 18px;
            }
            .desc-tab{
              font-family: $openSans-regular;
              line-height: 24px;
              color: $primary_color ;
              margin-bottom: 30px;
            }
            &.desktop-only{
              display: none;
            }
            &.mobile-only{
              display: flex;
              .tab-three-column-block-column{
                width: 100%;
                border-radius: 10px;
                background-color: #FFF;
                box-shadow: 0 3px 15px #467ca033;
                .header-tab{
                  background-color: #467CA0;
                  color: #FFF;
                  border-radius: 10px 10px 0 0;
                  font-size: 16px;
                  line-height: 22px;
                  padding: 15px 20px;
                }
                .content-tab-one{
                  font-family: $openSans-semi-bold;
                  font-size: 14px;
                  line-height: 24px;
                  padding: 15px 20px 7px;
                  color: $primary_color;
                }
                .content-tab-two,
                .content-tab-three
                {
                  padding: 7px 20px;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  align-items: center;
                  align-content: center;
                  .inner-header-tab{
                    font-family: $openSans-regular;
                    font-size: 14px;
                    line-height: 21px;
                    color: #388B7B;
                  }
                  .inner-content-tab{
                    font-family: $openSans-bold;
                    font-size: 16px;
                    line-height: 24px;
                    color: #388B7B;
                  }
                }
                .content-tab-three{
                  padding-bottom: 15px;
                  position: relative;
                  &:after{
                    content: '';
                    display: block;
                    width: calc(100% - 40px);
                    height: 1px;
                    opacity: 0.3;
                    background-color: #6BB2E3;
                    position: absolute;
                    bottom: 0;
                  }
                  &:last-child{
                    &:after{
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &::before{
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100vw;
      z-index: -1;
      background-color: #E3F1FB;
      transform: translate(-50%, 0);
    }
  }

  /*****Single Post ******/
  .single-post{
    .similar{
      .posts{
        justify-content: center;

        .item-post {
          width: calc(100% / 2 - 30px);

          &:last-child{
            margin-top: 45px;
          }
        }
      }
    }
  }

  /*****Blog ******/
  .blog{
    .listing-blog{
      .card{
        flex-direction: column;

        .one-half-part{
          width: 100%;
          padding-bottom: 0;

          figure{
            img{
              max-height: initial;
            }
          }
        }
        .two-half-part{
          width: 100%;
          padding: 30px;
        }
      }
    }
  }

  /*****News block ******/
  .news-block{
    .news{
      grid-template-columns: repeat(1, 1fr);
      .news-item{
        &.grid-item-1, &.grid-item-2, &.grid-item-3, &.grid-item-4{
          margin-left: 0;
        }
      }
    }
  }

  /*****Glossary block ******/
  .glossary{
    flex-direction: row-reverse;
    .alphabet{
      margin: 0;
      padding: 0 10px;
    }
    .accordion-wrapper{
      width: 100%;
      .accordion-item{
        margin-bottom: 30px;
        .accordion-header{
          padding: 20px 30px 20px 15px;
          svg{
            right: 10px;
          }
          .accordion-header-icon{
            &:before{
              right: 15px;
            }
          }
        }
        .accordion-body{
          .wrapper-body{
            padding: 20px;
          }
        }
      }
    }
  }

  /*****Template Medecin ******/
  .post-type-archive-medecin {
    .list-doctors {
      article {
        display: block;
        padding: 30px 21px;

        h2,h3, p,{
          text-align: center;

        }

        .picture{
          margin: 0 auto;
        }

        .doctor-profile{
          width: 100%;
          margin: 0;
        }

        .text-container{
          width: 100%;
          margin: 0;
          padding-right: 0;

          p{
            text-align: inherit;
            margin-left: 0;
          }

          &::before{
            content: none;
          }
        }

        .cta{

          &.detail{
            display: block;
            width: 50%;
            margin: 21px auto;

          }
        }
      }
    }
  }

  .single-medecin{

    h1, h2{
      text-align: center;
    }

    h1{
      &::after{
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .doctor{
      display: block;

      .picture{
        margin: 0 auto;
      }

      .doctor-profile{
        margin: 0;
        width: 100%;

        .rps-sector{

          p{
            text-align: center;
          }
        }
        .reasons{
          align-items: center;
          justify-content: center;
        }
      }

      .appointment{
        width: 100%;
        margin: 10px 0 0;

        .rdv{
          .content{
            justify-content: center;
            margin-bottom: 25px;
          }

          .cta-appointment{
            width: 50%;
            margin: 0 auto;
            display: block;
          }
        }
      }
    }
  }

  //**********Block*Frieze*****************//
  .block-frieze{
    margin-bottom: 50px;
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column {
          .advgb-column-inner {
            .block-frieze-inner {
              margin-bottom: 50px;
              .advgb-columns-container {
                .advgb-columns-row {
                  max-width: 320px;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  align-content: center;
                  flex-wrap: nowrap;
                  .block-frieze-columns{
                    flex: 0 1 100% !important;
                    max-width: 100%;
                    margin-bottom: 0 !important;
                    justify-content: center;
                    width: 100% !important;
                    .advgb-column-inner{
                      padding: 30px 40px 70px 30px !important;
                    }
                    &::after{
                      background-image: url("../../images/fleche-frieze-mobile.png");
                    }
                    &:first-child::before{
                      display: none;
                    }
                    &:last-child::after{
                      background-image: url("../../images/fleche-frieze-mobile-last.png");
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &::before{
      height: 40%;
    }
  }

  //**********Block TLV*******************//
  .tlv-begin-block {
    flex-direction: column;
    .tlv-begin-block-column-one {
      padding: 40px;
    }
    .tlv-begin-block-column-two {
      padding: 40px;
    }
  }
  .tlv-block {
    position: relative;
    width: 100vw;
    top: -180px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #E3F1FB;
    .title {
      padding-top: 50px;
      padding-bottom: 35px;
      font-size: 36px;
      line-height: 49px;
    }

    .form-step {
      .about-you {
        padding: 30px;
        .about-you-row {
          .row-input-age {
            .inner-row-input-age {
              flex: 0 1 50%;
              max-width: 50%;
            }
          }
          .row-input-correction {
            flex-wrap: wrap;
            .inner-row-input-correction {
              margin-bottom: 15px;
            }
          }
        }
        .button-row {
          justify-content: center;
        }

      }
      .instructions-calibrations {
        padding: 30px;
        .calibration-row {
          margin-bottom: 15px;
        }
      }
      .distance-vision {
        padding: 30px;
        .instruction-left-eye-row,
        .instruction-right-eye-row{

          .inner-instruction-eye-row {
            flex-wrap: wrap;
            justify-content: center;
            .instruction-one,
            .instruction-two {
              flex: 0 1 100%;
              max-width: 100%;
            }
            >div:not(:last-child) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
        .test-eye-row {
          width: 100%;
        }
      }
      .dmla-vision {
        padding: 30px;
        .instruction-left-eye-row,
        .instruction-right-eye-row{
          .inner-instruction-eye-row {
            flex-wrap: wrap;
            justify-content: center;
            .instruction-one,
            .instruction-two {
              flex: 0 1 100%;
              max-width: 100%;
            }
            >div:not(:last-child) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
        .test-eye-row {
          width: 100%;
          .test-amsler {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .result-row {
        width: 100%;
        padding: 30px;
        .result-asmler-eyes {
          width: 100%;
          flex-wrap: wrap;
          .inner-result-asmler {
            flex: 0 1 100%;
            max-width: 100%;
            flex-direction: column;
            align-items: center;
            .column-content {
              padding-left: 0;
              text-align: center;
            }
            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
        .result-global-eyes {
          width: 100%;
        }
        .tests-result-row {
          flex-wrap: wrap;
          width: 100%;
          .results-row {
            flex: 0 1 100%;
            max-width: 100%;
            &:last-child {
              border-left: 0 solid $light-blue;
              padding-left: 0;
              border-top: 2px solid $light-blue;
              padding-top: 20px;
              margin-top: 20px;
            }
          }
        }
        .button-row {
          flex-direction: column;
          flex-wrap: wrap;
          .button {
            &:not(:last-child) {
              margin-right: 0;
              margin-bottom: 15px;
            }
          }
        }
      }
    }

    ul{
      &.form-stepper {
        li {
          a {
            .form-stepper-circle {
              &::after {
                width: 50px;
                height: 50px;
              }
            }
          }
        }
        .label {
         display: none;
        }
      }
    }
  }

  .condition-popin-container {
    width: 90%;
  }

  /******** Block Centers List *******/
  .centers-list-block {
    .centers-list-container {
      .centers-list-row {
        .list-centers {
          .center-region {
            flex: 0 1 50%;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 1200px) {
  .container {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1420px) and (min-width: 1025px) {

  /*****Block Home centres Alaxione *******/
  .rdv-dispo-alaxione{
    .row{
      .cards{
        padding: 0 100px;

        &.col-4{
          padding: 0;
        }
        .card{
          .card-motif-take-rdv-link{
            .card-motif-take-rdv-link-inner{
              font-size: 14px;
            }
          }
          &:hover,
          &:focus,
          &:active {
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
          }

        }
      }
    }
  }

  //**********Block*Frieze*****************//
  .block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column {
          .advgb-column-inner {
            .block-frieze-inner {
              .advgb-columns-container {
                .advgb-columns-row {
                  .block-frieze-columns{
                    .advgb-column-inner{
                      padding: 40px 120px 40px 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 1350px) {

  //**********Block*Frieze*****************//
  .block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column {
          .advgb-column-inner {
            .block-frieze-inner {
              .advgb-columns-container {
                .advgb-columns-row {
                  .block-frieze-columns{
                    .advgb-column-inner{
                      padding: 30px 90px 30px 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 1200px) {

  //**********Block*Frieze*****************//
  .block-frieze{
    .advgb-columns-container{
      .advgb-columns-row{
        .advgb-column {
          .advgb-column-inner {
            .block-frieze-inner {
              .advgb-columns-container {
                .advgb-columns-row {
                  .block-frieze-columns{
                    .advgb-column-inner{
                      padding: 20px 25px 20px 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}
