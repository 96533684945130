.block-come-on-center{
  margin-top: 25px;
  padding-top: 30px;
  padding-bottom: 30px;

  .advgb-columns-container{
    .advgb-columns-row{
      .wp-block-advgb-column{
        .advgb-column-inner{
          overflow: hidden;
          .block-come-on-center-title{
            margin-bottom: 20px;
          }
          .wp-block-image{
            img{
              height: 100%;
              object-fit: cover;
              max-height: 480px;
            }
          }
          .block-come-on-center-button{
            .wp-block-advgb-button_link{
              display: inline-block;
              font-size: 16px;
              color: #ffffff;
              background-color: #467ca0;
              margin: 0;
              border-width: 1px;
              border-style: none;
              border-radius: 50px;
              position: relative;
              margin-top: 30px;
              padding: 14px 22px 14px 50px;
              line-height: 1;
            }
          }
          .block-come-on-center-adress{
            padding: 0 45px;
            margin-bottom: 30px;
            line-height: 24px;
            position: relative;
            &::before{
              content: '';
              display: block;
              width: 18px;
              height: 24px;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url("../../../images/Pin-blue.svg");
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom: -15px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #6BB2E3;
            }
          }
          .block-come-on-center-tel{
            padding: 0 0 0 45px;
            display: inline-block;
            margin-bottom: 30px;
            line-height: 24px;
            position: relative;
            a{
              font-size:16px;
              line-height: 24px;
              color:#153153;
              font-weight: normal;
            }
            &::before{
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url("../../../images/Phone-blue.svg");
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom: -15px;
              left: 0;
              width: 100vw;
              height: 1px;
              background-color: #6bb2e3;
            }
          }
          .block-come-on-center-mail{
            position: relative;
            display: inline-block;
            padding: 0 0 0 45px;
            float: right;
            margin-bottom: 30px;
            line-height: 24px;
            a{
              font-size:16px;
              line-height: 24px;
              color:#153153;
              font-weight: normal;
            }
            &::before{
              content: '';
              display: block;
              width: 24px;
              height: 17px;
              position: absolute;
              top: 4px;
              left: 0;
              background-image: url("../../../images/Contact.svg");
            }
          }
          .block-come-on-center-text{
            padding: 0 45px;
            margin: 30px 0;
            position: relative;
            &::before{
              content: '';
              display: block;
              width: 24px;
              height: 22px;
              position: absolute;
              top: 0;
              left: 0;
              background-image: url("../../../images/Calendar.svg");
            }
            &::after{
              content: '';
              display: block;
              position: absolute;
              bottom: -30px;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #6bb2e3;
            }
          }
          .block-come-on-center-button{
            padding: 0 45px;
            a{
              position: relative;
              background-color: #467ca0;
              margin-top: 30px !important;
              padding: 14px 22px 14px 50px;
              line-height: 1;
              &::before{
                content: '';
                display: block;
                width: 17px;
                height: 24px;
                position: absolute;
                top: 10px;
                left: 20px;
                background-image: url("../../../images/Comment-venir.svg");
              }
              &:hover{
                color: #ffffff !important;
                background-color: #153153 !important;
                box-shadow: 1px 1px 12px 0 #ccc;
                opacity: 1;
                transition: all 0.2s ease;
              }
            }
          }
          .block-come-on-center-map{
            img{
              width: 100%;
            }
          }
        }
      }
    }
  }
}