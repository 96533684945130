.confirmation-rdv {
    margin: 50px 0;
  .inner-row {
    padding: 30px;
    width: 67%;
    margin: 0 auto;
    -webkit-box-shadow: 3px 5px 10px 8px #CECECE;
    box-shadow: 3px 5px 10px 8px #CECECE;
    border-radius: 10px;
    .row_header {
      .inner_row_confirmer_rdv_header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .inner_row_confirmer_rdv_header_texte {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        p {
          text-align: center;
          padding: 5px 0;
        }
      }
    }
    .row_content {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #cecece;
      .inner_row_confirmer_rdv_content_header {
        p {
          text-align: center;
        }
      }
      .inner_row_confirmer_rdv_content_texte {
          display: flex;
          justify-content: space-between;
        &.jc-center {
          justify-content: center;
        }
      }
    }
  }
}