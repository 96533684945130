.block-video-with-title-text{
  .advgb-columns-container{
    .advgb-columns-row{
      .block-video-with-title-text-column-content{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        .advgb-column-inner{
          .block-video-with-title-text-title{
            margin-bottom: 20px;
            span{
              font-family: $openSans-bold;
            }
          }
          .block-video-with-title-text-content{
            line-height: 24px;
          }
        }
      }
    }
  }
}