body{
	font-family: "Arial Hebrew", Arial, sans-serif;
}
h1,h2,p,a{
	color: #0f0f11;
}

h1{
	font-family: $openSans-bold;
	font-size: 36px;
}

h2{
	font-family: $openSans-bold;
	font-size: 24px;
}

h3{
	font-family: $openSans-bold;
	font-size: 20px;
}

h4{
	font-family: $openSans-bold;
	font-size: 18px;
}
h1,h2,h3,h4,h5,h6 {
	span{
		font-family: $openSans-bold;
	}
}

p{
	font-family: $openSans-regular;
	font-size: 16px;
}

a{
	font-family: $openSans-regular;
	font-size: 16px;
	line-height: 2;
	letter-spacing: normal;
	font-weight : Bold;
	color : $primary_color;
	text-decoration: none;
}

input, select, textarea, span{
	font-family: $openSans-regular;
}

.container{
	max-width: 1440px;
	margin: 0 auto;
	padding: 0 90px;
	/** for compatibility with safari **/
	height: max-content;
	height: -webkit-max-content;
}

.title-line{
	position: relative;
	padding-left: 49px;

	&:before{
		content: "";
		display: block;
		width: 30px;
		height: 4px;
		background-color: $light-blue;
		position: absolute;
		top: 50%;
		left: 0;
	}
}

@media screen and (max-width: 720px) {
	.container{
		padding: 30px;
	}
}

.swiper-container,
.swiper-container-intro-block{
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}
#breadcrumbs{
	max-width: 1440px;
	margin: 16px auto;
	padding: 0 90px;
	font-family: $openSans-regular;
	color: $primary_color;
	.breadcrumb_last{
		font-family: $openSans-bold
	}
}
#primary{
	position: relative;
	z-index: 50;
}

#root{
	margin-bottom: 30px;
	min-height: 800px;
}

.d-none{
	display: none !important;
}
.d-block{
	display: block !important;
}
.d-flex{
	display: flex !important;
}

.post-type-archive-medecin, .single-medecin{

	h1, h2, a{
		font-family: $openSans-bold, sans-serif;
		color: $primary_color;
	}

	p,li{
		font-family: $openSans-regular, sans-serif;
		font-size: 16px;
		color: #707070;
	}

	span{
		font-family: $openSans-bold, sans-serif;
	}

	h1{
		font-size: 36px;
		font-weight: 700;
	}

	h2{
		font-size: 24px;
		font-weight: 700;
	}

	h3{
		font-family: $openSans-semi-bold, sans-serif;
		font-size: 16px;
		color: $primary_color;
	}

	.line{
		height: 1px;
		width: 100%;
		display: block;
		opacity: 0.3;

		&.blue{
			background-color: $light-blue;
		}
	}
}

.breadcrumbs{
	background-color: #F7F7F7;
	padding: 10px 0;
	box-shadow: inset 0 -7px 9px -7px rgb(0 0 0 / 10%);

	a, span{
		font-size: 14px;
		font-family: $openSans-regular, sans-serif;
		font-weight: normal;
	}

	span{
		color: $primary_color;
	}

	a{
		text-decoration: underline;
	}
}

.default-cta {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	margin-bottom: 30px;

	a {
		background-color: #467CA0;
		border-radius: 25px;
		color: $white;
		font-size: 16px;
		padding: 17px 40px;
		text-align: center;
		line-height: 1;

		&:hover {
			background-color: $primary_color;
		}
	}
}

h1{
	&.singular-title{
		font-family: $openSans-bold;
		color: $primary_color;
		padding: 38px 0 42px 50px;
		position: relative;
		margin-bottom: 50px;
		&:before, &:after{
			content: "";
			position: absolute;
			display: block;
			left: 0;
		}
		&:before{
			width: 30px;
			height: 4px;
			background-color: $light-blue;
			top: 50%;
		}
		&:after{
			width: 100%;
			height: 1px;
			opacity: 0.3;
			background-color: $light-blue;
			bottom: 0;
		}
	}
	&.title-alax-widget {
		font-family: $openSans-bold;
		color: $primary_color;
		text-align: center;
		padding: 40px 0;
		margin-bottom: 50px;
		font-size: 36px;
		@media only screen and (min-width: 1024px) {
			padding: 20px;
			margin-bottom: 20px;
		}
	}
}

.page-template-default{
	h2{
		margin-bottom: 20px;
	}
	h2, p{
		color: $primary_color;
	}
}

.expert{
	h1 {
		&.singular-title {
			&:before{
				background-color: $center-main-color;
			}
		}
	}
	.default-cta {
		a {
			background-color: $center-main-color;
			color: $center-text-color;
			font-family: $openSans-bold;

			&:hover {
				background-color: $primary_color;
				color: $white;
			}
		}
	}
}

.page-template-alaxione-widget{
	header{
		.flex-container{
			&.row{
				&.head{
					.container{
						flex-wrap: nowrap;
					}
				}
			}
		}
	}
}

.text-center {
	text-align: center;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.pl-0 {
	padding-left: 0;
}
.mt-3 {
	margin-top: 30px;
}
.font-normal {
	font-weight: normal;
}
.flex-space-between {
	justify-content: space-between !important;
}

.flex-justify-center {
	justify-content: center !important;
}
.flex-50 {
	flex: 0 1 50%;
	max-width: 48%;
}
.flex-100 {
	flex: 0 1 100%;
	max-width: 100%;
}

.p-0 {
	padding: 0 !important;
}

.mr-0 {
	margin: 0 !important;
}

.t-0 {
	top: 0 !important;
}