header{
  .flex-container.row {
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    &.prehead{
      margin: 0 auto;
      width: 100%;
      background-color: #153153;
      padding: 12px 0;
      .container{
        display: flex;
        margin: 0 auto;
        width: 100%;
        justify-content: space-between;
        >div{
          margin-right: 0;
        }
        .row{
          width: 100%;
          align-content: center;
          justify-content: center;
          align-items: center;
          text-align: center;
          .label{
            p,a{
              color: #ffffff;
              font-size: 14px !important;
              padding: 0;
              font-weight: normal;
              line-height: normal;
              font-family: $openSans-regular;
              position: relative;
              margin-left: 12px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: center;
              align-items: center;
              align-content: center;
            }
            &:nth-child(1){
              p{
                &::before{
                  content: '';
                  display: block;
                  width: 22px;
                  height: 20px;
                  background: url("../../images/pin.svg") no-repeat;
                  margin-right: 10px;
                }
              }
            }
            &:nth-child(2){
              p{
                &::before{
                  content: '';
                  display: block;
                  width: 15px;
                  height: 15px;
                  background: url("../../images/clock.svg") no-repeat;
                  background-size: contain;
                  margin-right: 12px;
                }
              }
            }
            &:nth-child(3){
              p{
                &::before{
                  content: '';
                  display: block;
                  width: 15px;
                  height: 15px;
                  background: url("../../images/secteur-card.svg") no-repeat;
                  background-size: contain;
                  margin-right: 12px;
                }
              }
            }
            &:nth-child(4){
              a{
                &::before{
                  content: '';
                  display: block;
                  width: 20px;
                  height: 20px;
                  background: url("../../images/phone.svg") no-repeat;
                  background-size: contain;
                  margin-right: 12px;
                }
              }
            }
          }
        }
      }
    }
    &.head{
      margin: 0 auto;
      width: 100%;
      padding: 20px 0;
      background-color: #FFFFFF;
      .container{
        display: flex;
        margin: 0 auto;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        >div{
          margin-right: 0;
        }
        .row-logo-header{
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          padding: 0;
          min-width: unset;
          margin-right: 0;
          flex-direction: row;
          align-items: center;
          align-content: center;
          max-width: 20%;
        }
      }
      .items-flex{
        display: inherit;
        flex-wrap: wrap;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
      #site-header{
        overflow: hidden;
        background-color: white;
        margin-right: 10px;
        #logo-header{
        }
      }
      .title-logo-header{
        .header-title{
          font-family: $openSans-regular;
          font-size: 12px !important;
          line-height: normal;
          color: #17385e;
          max-width: 155px;
          font-weight: unset;
          padding: 0;
          .center-name{
            color: #467CA0;
          }
        }
      }
      .row-contact-header{
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-end;
        align-items: center;
        .first-button-header{
          padding: 0;
          align-self: center;
          margin-right: 40px;
          #inner-first-button-header{
            border-bottom: 2px solid #6BB2E3;
            font-family: $openSans-bold;
            font-size: 16px;
            text-align: center;
            padding: 8px 0;
            color: #153153;
            &:hover{
              border-bottom: solid 2px #153153;
              color: #6BB2E3;
            }
          }
        }
        .second-button-header{
          border: 2px solid #6BB2E3;
          border-radius: 50px;
          align-self: center;
          margin-right: 0 !important;
          cursor: pointer;
          #inner-second-button-header{
            width: 100%;
            padding: 13px 25px 13px 50px;
            font-family: $openSans-bold;
            font-size: 16px;
            line-height: normal;
            text-align: center;
            color: #153153;
            position: relative;
            &:before{
              content: " ";
              top: 12px;
              left: 15px;
              width: 21px;
              height: 21px;
              position: absolute;
              background-image: url("../../images/person.svg");
              background-repeat: no-repeat;
            }
          }
          &:hover{
            border: solid 2px #153153;
            background: #153153;
            #inner-second-button-header{
              color: #ffffff;
              &:before{
                filter: brightness(1000) sepia(15) hue-rotate(0deg) saturate(0);
              }
            }
          }
        }
      }
    }
    .back-menu-mobile,
    .link-menu-mobile{
      display: none;
    }
    .menu-button-mobile{
      font-size: 0;
    }
    .nav{
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      max-width: 46%;
      .menu-main-menu-container{
        width: 100%;
      }
      .menu{
        max-width: $container-width;
        margin: 0 auto;
      }
      ul {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        li {
          display: inline-block;
          a{
            display: flex;
            font-family: $openSans-bold;
            font-size: 16px;
            text-align: center;
            color: #153153;
            position: relative;
            z-index: 992;
            padding: 4px 0;
            text-decoration: none;
            &:hover{
              color: #467CA0;
            }
            &::before{
              content: "";
              width: 100%;
              height: 3px;
              position: absolute;
              left: 0;
              bottom: 0;
              background: #fff;
              transition: 0.5s transform ease;
              transform: scale3d(0,1,1);
              transform-origin: 0 50%;
            }
            &::before{
              background: #467CA0;
              transform-origin: 50% 50%;
            }
          }
          &.current-menu-ancestor{
            > a{
              font-family: $openSans-extra-bold;
              &::before{
                transform: scale3d(1,1,1);
              }
            }
            .current-menu-item{
              > a{
                font-family: $openSans-extra-bold;
                &::before{
                  transform: scale3d(1,1,1);
                  width: calc(100% - 40px );
                  height: 1px;
                  left: 15px;
                  bottom: 20px;
                }
              }
            }
          }
          &.menu-item-has-children{
            position: relative;
            ul.sub-menu{
              display: none;
            }
            &:hover{
              ul.sub-menu{
                position: absolute;
                display: block;
                top: 35px;
                left: -20px;
                padding-top: 15px;
                z-index: 999;
                background-color: white;
                width: calc( 160% + 50px );
                overflow: visible;
                li{
                  width: 100%;
                  a{
                    padding: 0 20px 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}