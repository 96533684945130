.trustville-block{
  padding: 50px 0;
  position: relative;
  .advgb-columns-container{
    background-color: #F7F7F7;
    border-radius: 10px;
    .advgb-columns-row {
      .wp-block-advgb-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        padding: 40px 50px;
        .advgb-column-inner{
          h2,p{
            color: #17385e;
          }
          p{
            padding-right: 95px;
          }
          .trustville-link{
            margin-top: 20px;
            a{
              text-transform: uppercase;
              border: none;
              border-bottom: 3px solid #6bb2e3;
              padding: 0 34px 11px 0;
              border-radius: 0;
              position: relative;
              &::after{
                content: '';
                display: block;
                width: 19px;
                height: 18px;
                position: absolute;
                top: 9px;
                right: 0;
                background-image: url("../../../images/cta-arrow-right.svg");
                background-repeat: no-repeat;
              }
              &:hover{
                border-bottom: 3px solid #17385e !important;
                &::after{
                  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
                }
              }
            }
          }
        }
        &.trustville-block-column-title{
          align-items: flex-start;
          padding: 40px 40px 0;
        }
      }
    }
    .trustville-block-container{
      flex-grow: 2 !important;
      .guestapp-widget {
        min-height: 45px;
        border-radius: 0;
        background-color: transparent;
        display: flex;
        align-items: center;
        .guestapp-logo {
          width: 62px;
          height: 62px;
          border-right: 0;
          margin: 0;
          padding: 15px;
          border-radius: 10px;
          background-color: #fff;
          box-shadow: 0 3px 15px rgba(70,124,160,.4);
          box-sizing: initial;
        }
        .guestapp-rating {
          position: relative;
          display: flex;
          width: 200%;
          flex-direction: column;
          margin-left: 20px;
          text-align: center;
          .guestapp-note {
            display: flex;
            margin-bottom: 5px;
            .guestapp-stars {
              background-repeat: no-repeat;
              display: block;
              height: 25px;
              width: 25px;
              margin-top: 7px;
              margin-right: 2px;
              &.guestapp-stars-full {
                background-image: url("../../../images/full-star.svg");
              }
              &.guestapp-stars-empty {
                background-image: url("../../../images/empty-star.svg");
              }
              &.guestapp-stars-half {
                background-image: url("../../../images/half-star.svg");
              }
            }
            strong {
              line-height: 23px;
              font-size: 17px;
              font-weight: 700;
              margin-left: 10px;
              margin-top: 10px;
              color: #153153;
            }
            .guestapp-on-5 {
              font-size: 15px;
              font-weight: 700;
              color: #153153;
              line-height: 23px;
              margin-top: 10px;
            }
          }
          .guestapp-count {
            font-size: 15px;
            line-height: 23px;
            letter-spacing: normal;
            font-weight: bold;
            padding: 0;
            display: block;
            width: 100%;
          }
        }
      }
    }
    .trustville-block-column-text{
      position: relative;
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% - 80px);
        background-color: #6BB2E3;
        left: 0;
      }
    }
  }
}
