.block-frieze{
  margin-bottom: 100px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100vw !important;
  z-index: 70;
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column {
        .advgb-column-inner {
          .block-frieze-title{
            font-family: $openSans-bold;
            font-size: 28px;
            line-height: 38px;
            text-align: center;
            color: $white;
            margin-top: 100px;
          }
          .block-frieze-content{
            max-width: 730px;
            font-family: $openSans-regular;
            font-size: 15px;
            line-height: 26px;
            text-align: center;
            color: $white;
            margin: 30px auto 50px;
          }
          .block-frieze-inner {
            margin-bottom: 100px;
            .advgb-columns-container {
              .advgb-columns-row {
                position: relative;
                max-width: $container-width;
                .block-frieze-columns{
                  position: relative;
                  display: flex;
                  flex: 0 1 25% !important;
                  max-width: 25%;
                  align-items: stretch;
                  .advgb-column-inner{
                    position: relative;
                    z-index: 2;
                    padding: 40px 130px 40px 0;
                    .block-frieze-columns-number{
                      font-family: $openSans-bold;
                      font-size: 40px;
                      line-height: 30px;
                      text-align: center;
                      color: $primary_color;
                    }
                    .block-frieze-columns-title{
                      display: flex;
                      font-family: $openSans-bold;
                      font-size: 20px;
                      line-height: 25px;
                      text-align: center;
                      color: $primary_color;
                      margin: 10px 0;
                      min-height: 50px;
                      align-items: center;
                      justify-content: center;
                    }
                    .block-frieze-columns-content{
                      font-family: $openSans-regular;
                      font-size: 15px;
                      line-height: 26px;
                      text-align: center;
                      padding: 0 35px;
                      color: $primary_color;
                    }
                  }
                  &::after{
                    content: " ";
                    display: block;
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, 0);
                    width: 100%;
                    height: 100%;
                    background-image: url("../../../images/fleche-frieze-desktop.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                  }
                  &:first-child::before{
                    content: " ";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: -25%;
                    transform: translate(-50%, 0);
                    background-color: $white;
                  }
                  &:last-child::after{
                    content: " ";
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: 0;
                    transform: translate(-50%, 0);
                    width: 100%;
                    height: 100%;
                    background-image: url("../../../images/fleche-frieze-desktop-last.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100vw;
    height: 100%;
    background-color: $primary_color;
  }
}