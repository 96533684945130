.container{
  &.single, &.detail {

    h1 {
      position: relative;
      font-size: 36px;
      margin: 40px 0 36px 0;
      font-weight: 700;
      color: #153153;
      &.title-line{
        &:before{
          top: 23px;
        }
      }
    }

    h3, p, li {
      color: #153153;
    }

    article{
      p {
        margin-bottom: 10px;
      }
      .wp-block-gallery{
        margin-top: 30px;

        ul{
          margin: 0;
        }
      }
      h2,h3{
        color: #153153;
        margin-bottom: 10px;
      }
    }

    ul{
      margin-left: 18px;

      li{
        list-style-type: disc;
        font-family: $openSans-regular, sans-serif;
        font-size: 16px;
      }
    }

    .categorie-item{
      font-size: 12px;
      font-weight: 700;
      background-color: #FFE886;
      border-radius: 50px;
      padding: 2.5px 15px;
      margin-right: 10px;

      &:last-child{
        margin-right: 0;
      }
    }

    .head-article{
      padding: 25px 0;
      margin-bottom: 50px;
      border-top: solid 1px rgba(107, 178, 227, .3);
      border-bottom: solid 1px rgba(107, 178, 227, .3);
      display: flex;
      align-items: center;

      .date{
        font-size: 16px;
        color: #153153;
        font-weight: 700;
      }

      .categories{
        margin-left: 19px;
        display: flex;
      }

      .network{
        flex-grow: 1;
        text-align: right;

        a{
          line-height: normal;
          font-size: 0;
          cursor: pointer;

          &:not(:last-child){
            margin-right: 10px;
          }
        }
      }
    }

    .wp-block-image{
      margin: 30px 0 50px 0;

      img{
        width: 100%;
        object-fit: cover;
      }
    }

    .block-double-cta{
      margin-top: 44px;

      &.wp-block-advgb-columns{
        &.advgb-columns-wrapper{
          .advgb-columns-container{
            .advgb-columns {
              .wp-block-advgb-column{

                &:first-child {
                  .advgb-column-inner {
                    float: right;
                    margin-right: 25px;
                  }
                }

                &:last-child {
                  .advgb-column-inner {
                    float: left;
                    margin-left: 25px;
                  }
                }
              }

              &.gutter-50 >.advgb-column{
                &:not(:first-child){
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }

    .block-text-with-img{
      .advgb-columns-container{
        .advgb-columns-row{
          .block-text-with-img-column-text{
            background-color: #ffffff;
          }
        }
      }

      .advgb-column{
        position: relative;

        .wp-block-image{
          img{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .similar{
      margin-top: 100px;

      h2{
        color: #153153;
      }

      .posts{
        display: flex;
        margin: 50px -15px 60px -15px;
        flex-wrap: wrap;

        .item-post{
          width: calc(100% / 3 - 30px);
          margin: 0 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          h3{
            font-size: 20px;
            color: #153153;
            margin-bottom: 10px;
          }

          p{
            color: #707070;
          }

          figure{
            img{
              height: 265px;
              width: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .item-info{
            display: flex;
            margin: 15px 0 5px 0;

            p{
              color: #153153;
            }

            .categories{
              margin-left: 10px;
              display: flex;
              flex-wrap: wrap-reverse;

              .categorie-item{
                color: #153153;
                margin-bottom: 5px;

                &:not(:last-child){
                  margin-right: 5px;
                }
              }
            }
          }

          .cta{
            margin-top: 10px;

            a {
              position: relative;
              color: #153153;
              font-size: 14px;

              &:hover{

                &:after{
                  background-color: #17385e;
                }

                &:before {
                  filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
                }
              }

              &:after{
                content: "";
                display: block;
                width: calc(100% + 33px);
                height: 2px;
                position: absolute;
                bottom: -10px;
                left: 0;
                background-color: #6BB2E3;
              }

              &:before {
                content: "";
                display: block;
                width: 15px;
                height: 15px;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                right: -33px;
                background-image: url(/app/themes/pv-centre/dist/images/cta-arrow-right.svg?542b88b…);
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
      .news-cta {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 30px;

        a {
          background-color: #467CA0;
          border-radius: 25px;
          color: #ffffff;
          font-size: 16px;
          padding: 17px 40px;
          text-align: center;
          line-height: 1;

          &:hover {
            background-color: #153153;
          }
        }
      }
    }
  }
}