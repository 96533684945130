.block-medecin{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          .block-medecin-title{
            position: relative;
            padding: 40px 0 35px 50px;
            margin-bottom: 20px;
            &::before{
              content: '';
              display: block;
              width: 30px;
              height: 4px;
              background-color: #6BB2E3;
              position: absolute;
              top: 52px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.medecin-block{
  .medecin-block-container{
    .medecin-block-row{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      .medecin-block-is-one-four{
        width: 25%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 25px;
        .medecin-block-inner{
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-direction: column;
          flex-wrap: wrap;
          .medecin-block-inner-row{
            .medecin-block-img-column{
              .medecin-block-image-block{
                border-radius: 100%;
                overflow: hidden;
                width: fit-content;
                box-shadow: 0 0 0 3px #6BB2E3;
                margin: 0 auto 15px;
                .picture-medecin{
                  max-width: 200px;
                }
              }
            }
            .medecin-block-content-column{
              .medecin-block-content-column-inner{
                text-align: center;
                .medecin-name{
                  line-height: 27px;
                }
                .medecin-speciality{
                  color: #707070;
                }
              }
            }
          }
        }
        &:hover {
          .medecin-block-inner{
            .medecin-block-inner-row{
              .medecin-block-img-column{
                .medecin-block-image-block{
                  box-shadow: 0 0 0 10px #6BB2E34D;
                }
              }
              .medecin-block-content-column{
                .medecin-block-content-column-inner{
                  .medecin-name{
                    text-decoration: underline;
                    text-underline-position: under;
                  }
                }
              }
            }
          }
        }
      }
    }
    .medecin-block-link{
      width: fit-content;
      margin: 0 auto;
      .medecin-block-link-inner{
        display: block;
        font-family: $openSans-bold;
        background-color: #467ca0;
        border-radius: 25px;
        color: #fff;
        font-size: 16px;
        padding: 17px 40px;
        text-align: center;
        line-height: 1;
        &:hover{
          background-color: #153153;
        }
      }
    }
  }
}