.blog, .category-post{

  h2{
    color: #153153;
  }

  .separator{
    margin: 0 3px;
  }

  .title{
    margin-bottom: 50px;

    h1{
      position: relative;
      font-size: 36px;
      margin: 40px 0 36px 50px;
      font-weight: 700;

      &::before{
        content: "";
        position: absolute;
        left: -50px;
        top: 50%;
        background-color: #6BB2E3;
        height: 4px;
        width: 30px;
      }
    }
    .line {
      margin-bottom: 50px;
      height: 1px;
      width: 100%;
      display: block;
      opacity: 0.3;

      &.blue{
        background-color: #6BB2E3;
      }
    }
  }

  .text-categ{
    margin-bottom: 50px;
    p{
      color: #153153;
    }
  }

  .categorie-item{
    font-size: 12px;
    font-weight: 700;
    background-color: #FFE886;
    border-radius: 50px;
    padding: 2.5px 15px;
    margin-right: 10px;

    &:last-child{
      margin-right: 0;
    }
  }

  .card{
    display: flex;
    margin-bottom: 30px;

    &:last-child{
      margin-bottom: 50px;
    }

    h2{
      font-size: 24px;
      margin-bottom: 15px;
    }

    h3{
      margin-bottom: 20px;
    }

    .one-half-part, .two-half-part{
      position: relative;
      width: 50%;
    }

    .two-half-part{
      padding: 43px 61px;

      .text-container{
        .info-post{
          display: flex;
          margin-bottom: 16px;
        }

        .more{
          margin-top: 10px;

          a {
            position: relative;
            color: #153153;
            font-size: 14px;
            text-transform: uppercase;

            &:hover{

              &:after{
                background-color: #17385e;
              }

              &:before {
                filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
              }
            }

            &:after{
              content: "";
              display: block;
              width: calc(100% + 33px);
              height: 2px;
              position: absolute;
              bottom: -10px;
              left: 0;
              background-color: #6BB2E3;
            }

            &:before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              right: -33px;
              background-image: url(/app/themes/pv-centre/dist/images/cta-arrow-right.svg?542b88b…);
              background-repeat: no-repeat;
            }
          }
        }

        .desc{
          font-size: 20px;
        }
        .time{
          margin-right: 10px;
        }
      }
    }
  }

  .sticky-post{
    img{
      min-height: 400px;
      object-fit: cover;
    }
  }

  .listing-blog{
    .filter-posts{
      margin-top: 50px;
      margin-bottom: 30px;

      .searchandfilter {

        li {
          display: flex;
          padding: 0;

          &:nth-child(1){
            width: 10%;
          }

          &:nth-child(2),&:nth-child(3){
            width: 35%;
          }

          &:nth-child(4){
            width: 20%;
          }

          input,select{
            padding: 13px;
            border-radius: 10px;
            width: 100%;
            border: solid 2px #ffffff;
          }

          &:not(:first-child){
            margin-left: 37px;
          }
        }

        input[type=submit] {
          background-color: #153153;
          font-size: 16px;
          color: #ffffff;
          border-radius: 25px;
          border: solid 2px #ffffff;
          cursor: pointer;
        }

        p{
          margin: 0;
          font-size: 16px;
          font-family: $openSans-regular;
          align-self: center;
          width: 100%;
          color: #ffffff;
          white-space: nowrap;
        }

        ul {
          padding: 26px 30px;
          width: 100%;
          background-color: #153153;
          border-radius: 10px;
          color: #ffffff;
          display: flex;
          align-items: center;
        }
      }

    }
    .card{
      background-color: #F7F7F7;
      border-radius: 10px;

      .two-half-part{
        width: 65%;
        padding: 30px 30px 30px 0;
      }

      .one-half-part{
        width: 35%;
        padding: 30px;

        figure{
          height: 100%;

          img{
            max-height: 241px;
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .pagination{
    text-align: center;
    margin-bottom: 80px;

    .page-numbers{
      font-size: 20px;
      margin-right: 10px;
      font-family: $openSans-bold;

      &.dots{
        font-size: 20px;
      }
      &.current{
        color: #707070;
        font-family: $openSans-regular;
      }
    }
  }
}