.first-appointment-block {
  display: flex;
  margin: 50px 0;
  flex-wrap: wrap;
  justify-content: space-between;
  .content-row {
    margin-left: 40px;
    flex: 0 1 50%;

    p{
      color: #153153;
    }
  }
  .appointment{
    position: relative;
    flex: 0 1 30%;

    .rdv{
      padding: 40px;
      background-color: #F7F7F7;

      .content{
        display: flex;
        margin-bottom: 54px;

        svg{
          align-self: center;
        }

        .text-rdv{
          margin-left: 15px;

          .date{
            font-family: OpenSans-bold,sans-serif;
            white-space: nowrap;
          }

          p{
            color: #153153;

            &:first-child{
              white-space: nowrap;
            }
          }
        }
      }
      .cta-appointment{
        background-color: #467CA0;
        color: #ffffff;
        font-size: 16px;
        padding: 14px 23px;
        border-radius: 24px;
        line-height: 1;
        text-align: center;
        display: inline-block;
        width: 100%;

        &.disabled{
          background-color: #b5cad9;
          color: #dfeaf2;
          pointer-events: none;
        }
      }
    }
  }
}