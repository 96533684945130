.glossary{
  display: flex;
  margin-top: 60px;
  margin-bottom: 100px;

  h3{
    color: #153153;
    margin: 20px 0;
  }
  .alphabet{
    margin-right: 49px;

    p{
      cursor: pointer;
      text-align: center;

      a{
        font-family: $openSans-bold;
        font-size: 36px;
        color: #707070;

        &:hover
        {
          color: #6BB2E3;
        }
      }
    }
  }
  .accordion-wrapper{
    width: 100%;

    .accordion-item{
      margin-bottom: 30px;

      &.active{
        .accordion-header{
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          .accordion-header-icon{
            svg{
              display: none;
            }
            &:before{
              content: "";
            }
          }
        }
        .accordion-body{
          background-color: #f7f7f7;
          border-style: solid;
          border-width: 0;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }

      .accordion-header{
        position: relative;
        cursor: pointer;
        background-color: #f7f7f7;
        color: #467ca0;
        border-style: solid;
        border-width: 0;
        border-radius: 10px;
        padding: 19px 70px 19px 30px;

        svg{
          display: block;
          position: absolute;
          right: 30px;
          top: 50%;
          transform: translateY(-50%);
        }
        .accordion-header-icon{
          &:before{
            position: absolute;
            right: 30px;
            width: 20px;
            height: 2px;
            background-color: #467ca0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .accordion-header-title{
          font-size: 24px;
          margin: 0;
        }
      }
      .accordion-body{
        padding: 0;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.3s ease-out;

        .wrapper-body{
          padding: 20px 70px 30px 30px;
        }

        ul{
          font-size: 16px;
          color: #153153;
          padding-left: 19px;
          font-family: "OpenSans";

          li{
            list-style-type: disc;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}