.rdv-dispo-alaxione{
  .rdv-dispo-popin-container{
    display: none;

    &.active{
      display: block;
    }
    .blur{
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #153153;
      opacity: .8;
      z-index: 100;
    }

    .popin{
      background-color: #FFFFFF;
      border-radius: 10px;
      padding: 60px 80px;
      max-width: 826px;
      position: fixed;
      top: 50%;
      z-index: 100;
      left: 50%;
      transform: translate(-50%, -50%);

      @media only screen and (max-width: 1024px) {
        width: calc(100% - 20px);
        padding: 50px 30px;
      }

      .close-popin{
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;
      }

      .title{
        color: #153153;
        text-align: left;
        font-size: 24px;
        font-family: "OpenSans-bold";
        margin-bottom: 30px;

        @media only screen and (max-width: 1024px) {
          text-align: center;
        }
      }

      .text{
        p {
          margin-bottom: 30px;
          color: #153153;
          font-size: 16px;
        }
      }

      .cta-container{
        position: relative;
        margin-top: 40px;

        .cta{
          font-family: "OpenSans-bold";
          padding: 12px 25px 12px 51px;
          border: solid 2px #6BB2E3;
          border-radius: 50px;
        }
        svg{
          cursor: pointer;
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
