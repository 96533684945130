.news-block{
  position: relative;
  padding: 50px 0;

  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100vw;
    z-index: -1;
    background-color: #e3f1fb;
    transform: translate(-50%, 0);
  }

  .title-line{
    margin-bottom: 50px;
    color: #153153;
  }

  .news{
    display: -ms-grid; /* version IE */
    -ms-grid-columns : 50% 1fr 0; /* version IE */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    margin-bottom: -34px;

    .news-item{
      background-color: #ffffff;
      border-radius: 10px;
      display: flex;
      margin-bottom: 34px;
      width: calc(100% - 15px);

      /* version IE */
      &.grid-item-1{
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      /* version IE */
      &.grid-item-2{
        -ms-grid-column: 2;
        -ms-grid-row: 1;
        margin-left: 15px;
      }

      /* version IE */
      &.grid-item-3{
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      /* version IE */
      &.grid-item-4{
        -ms-grid-column: 2;
        -ms-grid-row: 2;
        margin-left: 15px;
      }

      figure{
        position: relative;
        width: 40%;

        img{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }

      .news-text{
        width: calc(60% + 30px);
        padding: 30px;
        position: relative;

        h3{
          margin-bottom: 10px;
          color: #153153;
        }

        p{
          margin-bottom: 46px;
          color: #707070;
        }

        .cta{
          position: absolute;
          display: block;
          bottom: 30px;
          left: 30px;

          a {
            position: relative;
            color: #153153;
            font-size: 14px;
            font-family: OpenSans-bold,sans-serif;

            &:hover{

              &:after{
                background-color: #17385e;
              }

              &:before {
                filter: brightness(0.2) sepia(1) hue-rotate(180deg) saturate(5);
              }
            }

            &:after{
              content: "";
              display: block;
              width: calc(100% + 33px);
              height: 2px;
              position: absolute;
              bottom: -10px;
              left: 0;
              background-color: #6BB2E3;
            }

            &:before {
              content: "";
              display: block;
              width: 15px;
              height: 15px;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              right: -33px;
              background-image: url(/app/themes/pv-centre/dist/images/cta-arrow-right.svg?542b88b…);
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
}

.news-cta{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 52px;

  a{
    background-color: #467CA0;
    border-radius: 25px;
    color: #ffffff;
    font-size: 16px;
    padding: 14px 40px;
    text-align: center;
    line-height: 1;

    &:hover{
      background-color: #153153;
    }
  }
}