.annuler-rdv {
    margin: 50px 0;
  .inner-row {
    padding: 30px;
    width: 67%;
    margin: 0 auto;
    -webkit-box-shadow: 3px 5px 10px 8px #CECECE;
    box-shadow: 3px 5px 10px 8px #CECECE;
    border-radius: 10px;
    .row_header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .row_content {
      padding-top: 10px;
      margin-top: 10px;
      border-top: 1px solid #cecece;
      p {
        text-align: center;
      }
      a {
        display: block;
        margin: 0 auto;
        width: fit-content;
      }
      form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        .btn {
          appearance: none;
          display: block;
          padding: 5px 15px;
          background-color: $primary_color;
          border: 1px solid $primary_color;
          color: $white;
          border-radius: 5px;
          cursor: pointer;
          &:last-child {
            margin-left: 25px;
          }
          &.btn-secondary {
            background-color: $white;
            color: $primary_color;
          }
        }
      }
    }
  }
}