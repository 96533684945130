.block-title-border-bottom{
  .advgb-columns-container{
    .advgb-columns-row{
      .advgb-column{
        .advgb-column-inner{
          h1,h2,h3,h4{
            font-family: $openSans-bold;
            color: #153153;
            padding: 38px 0 42px 50px;
            position: relative;
            margin-bottom: 50px;
            &:before{
              content: "";
              display: block;
              width: 30px;
              height: 4px;
              background-color: #6bb2e3;
              position: absolute;
              top: 50%;
              left: 0;
            }
            &:after{
              content: '';
              display: block;
              width: 100%;
              height: 1px;
              opacity: 0.3;
              background-color: #6BB2E3;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}