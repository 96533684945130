.container {
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 ($column-gap / 2);
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-wrap{
  flex-wrap: wrap;
}

.flex-12{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.flex-6{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

[class^="col-"] {
  padding: 0 ($column-gap / 2);
}

@for $i from 0 through $column-count {
  .col-#{$i} {
    width: percentage($i / $column-count);
  }

  .offset-#{$i} {
    margin-left: percentage($i / $column-count);
  }
}

@for $i from 1 through $column-count {
  @each $key, $value in $breakpoints {
    @media only screen and (max-width: $value) {
      .#{$key}-col-#{$i} {
        width: percentage($i / $column-count);
      }

      .#{$key}-offset-#{$i} {
        margin-left: percentage($i / $column-count);
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  .s-offset-0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .xs-offset-0 {
    margin-left: 0;
  }
}

#get-grid {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
}

#get-grid > div {
  margin: 0 ($column-gap / 2);
  position: relative;
  width: calc(100% - #{$column-gap});
}

#get-grid > div > span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(239, 164, 84, 0.51);
  display: block;
}
